import React from "react";
import Profile from "./Buyer/Dashboard/Profile";
import Address from "./Buyer/Dashboard/Address"
import MyPayments from "./Buyer/Dashboard/MyPayments";
import Order from "./Buyer/Dashboard/Order"
import AllOrders from "./Buyer/Dashboard/AllOrders";
import TrackOrder from "./Buyer/Dashboard/TrackOrder"
import InquiryList from "./Buyer/Dashboard/InquiryList";
import ShopInbox from "./Buyer/Dashboard/ShopInbox";


const BuyerSidebarContent = ({ active, setViewProfile }) => {


  return (
    <div className="w-full h-full py-8">
      {active === 1 && <Profile setViewProfile={setViewProfile}/>}
      {active === 2 && <Address />}
      {active === 3 && <MyPayments />}
      {active === 4 && <Order />}
      {active === 5 && <AllOrders />}
      {active === 6 && <TrackOrder />}
      {active === 7 && <InquiryList />}
      {active === 8 && <ShopInbox />}
      
    </div>
  );
};

export default BuyerSidebarContent;
