import React, { useState } from "react";
import styles from "../Styles/Style";
import ViewProfile from "../Components/Layout/ProfileContent/ViewProfile";
import BuyerSidebarContent from "../Components/Layout/BuyerSidebarContent"
import { motion } from "framer-motion";
import BuyerHeader from "../Components/Layout/Buyer/BuyerHeader";
import BuyerSidebar from "../Components/Layout/BuyerSidebar";

const BuyerProfilePage = () => {
  const [active, setActive] = useState(1);
  const [viewProfile, setViewProfile] = useState(false);

  return (
    <>
      {viewProfile && <ViewProfile setViewProfile={setViewProfile} />}
      <BuyerHeader/>
      <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        transition={{ duration: 0.5 }}
        className={`${styles.section} flex bg-[#f5f5f5] w-full`}>
        <div className="w-[50px] 800px:w-[335px] flex items-center justify-center">
          <BuyerSidebar active={active} setActive={setActive} />
        </div>
        <BuyerSidebarContent active={active} setViewProfile={setViewProfile} />
      </motion.div>
    
    </>
  );
};

export default BuyerProfilePage;
