import React, { useEffect, useState } from "react";
import Header from "../Components/Layout/Header";
import { useSearchParams } from "react-router-dom";
import Product from "../Components/Layout/Product";
import Footer from "../Components/Layout/Footer";
import styles from "../Styles/Style";
import ProductSidebar from "../Components/Layout/ProductSidebar";
import { motion } from "framer-motion";
import BuyerHeader from "../Components/Layout/Buyer/BuyerHeader";

const ProductPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl + "/api/product/all/");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredData = categoryData
    ? data.filter((product) => product.category === categoryData)
    : data;

  return (
    <>
      <Header />

      <div className="flex">
        <div className="w-1/4">
          <ProductSidebar />
        </div>

        <div className="w-full sm:w-3/4 lg:w-4/5">
          <motion.div
            initial={{ opacity: 0, x: -500 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -500 }}
            transition={{ duration: 0.5 }}
            className={styles.section}
          >
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0 mt-12">
              {filteredData.map((item, index) => (
                <Product data={item} key={index} />
              ))}
            </div>

            {filteredData.length === 0 ? (
              <h1 className="text-center font-bold md:text-2xl text-gray-600 mb-16">
                Product not found!
              </h1>
            ) : null}
          </motion.div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductPage;