import React from "react";
import LoginSeller from "../Components/Layout/Seller/LoginSeller";

const SellerLoginPage = () => {
  return (
    <>
      <LoginSeller />
    </>
  );
};

export default SellerLoginPage;
