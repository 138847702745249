import React from 'react';
import Features from '../Components/Layout/Features';
import Header from '../Components/Layout/Header';
import AboutUs from '../Components/Layout/AboutUs';
import Footer from '../Components/Layout/Footer'

const AboutPage = () => {
  return (
    
    <div className="min-h-screen bg-white">
      <Header />
      <AboutUs />
      <Features />
      <Footer />
    </div>
  );
};

export default AboutPage;
