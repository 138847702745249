import React from "react";
import Header from "../Components/Layout/Header";
import Hero from "../Components/Layout/Hero";
import Footer from "../Components/Layout/Footer";
import Values from "../Components/Layout/Values";
import { motion } from "framer-motion";
import MarketDashboard from "../Components/Layout/MarketDashboard";
import GetStarted from "../Components/Layout/GetStarted";
import ExportReadiness from "../Components/Layout/ExportReadiness";
import Carousel from "../Components/Layout/Carousel";
import IndemandProducts from "../Components/Layout/IndemandProducts";
import ValueProposition from "../Components/Layout/ValueProposition";


const HomePage = () => {
  return (
    <>
      <Header activeHeading={1} />
      <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        transition={{ duration: 0.5 }}>
        <Hero />
        <Carousel />
        <GetStarted />
        <IndemandProducts />
        <MarketDashboard />
        <Values />
        <Footer />
      </motion.div>
    </>
  );
};

export default HomePage;
