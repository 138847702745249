import React from "react";
import Product from "../../assets/beans.png";

const Trade = () => {
  return (
    <div className="bg-product-image bg-cover bg-center h-150 w-full flex flex-col 800px:flex-row items-start">
      <div className="w-full 800px:w-[80%] p-5 flex flex-col justify-start mt-5">
        <h2 className="text-[28px] 800px:text-[52px] font-[700] capitalize text-[#000000] leading-[1.2] mb-6 800px:mb-10 mt-6 800px:mt-10">
          <span className="text-[#4A0000]">Looking for locally produced</span><br />
          <span className="text-[#4A0000] text-bold">African Products?</span> 
        </h2>

        <div className="flex flex-col justify-start items-start space-y-5">
          <p className="font-[400] font-Poppins text-[14px] 800px:text-[16px] text-[#000000] text-left">
          Explore our vast category of products and ensure your countries' access <br />
           to scarce supplies in a transparent manner.
          </p>

        </div>
      </div>
      <div
        className="w-full 800px:w-[50%] h-[10vh] 800px:h-full bg-no-repeat bg-right"
        style={{
          backgroundImage: `url(${Product})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "370px",
          height: "350px",
        }}
      ></div>
    </div>
  );
};

export default Trade;
