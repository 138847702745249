import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../Styles/Style";
import Product from "./Product";

const InDemand = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(apiUrl + "/api/product/all/");
        const products = await response.json();

        const sortedProducts = products.sort((a, b) => b.total_sell - a.total_sell);
        const topTenProducts = sortedProducts.slice(0, 4);

        setData(topTenProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className={`${styles.section} mt-[30px]`}>
        <div className="flex justify-between items-center mb-[20px]">
          <div className="flex items-center">
            <div className="w-5 h-7 bg-[#4A0000] mr-2.5 rounded-sm"></div>
            <h2 className="text-xl font-semibold text-[#4A0000]">Products</h2>
          </div>
        </div>
        <div className={`${styles.heading}`}>
          <h1>In-Demand</h1>
        </div>

        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-1 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12 border-0 ">
          {data.map((product, index) => (
            <Product data={product} key={index} />
          ))}
        </div>
      </div>
      <div className="flex justify-center">
        <Link to="/trade">
          <button className="bg-[#4A0000] text-white font-bold py-2 px-4 rounded hover:bg-[#600000de]">
            View All Products
          </button>
        </Link>
      </div>
      <hr style={{ margin: '50px 0', borderTop: '1px solid gray', width: '100%' }} />
    </div>
  );
};

export default InDemand;