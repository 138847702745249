import React from 'react';
import Beauty from '../../assets/beauty.png'
import Oil from '../../assets/Oil.png'
import Grains from '../../assets/Seeding.png'
import Flour from '../../assets/flour.png'
import styles from "../../Styles/Style";

function Categories() {
  return (

    <div className={`${styles.section} mt-[30px]`}>
      <div className="flex justify-between items-center mb-[20px]">
          <div className="flex items-center">
        <div className="w-5 h-7 bg-[#4A0000] mr-2.5 rounded-sm"></div>
            <h2 className="text-xl font-semibold text-[#4A0000]">Categories</h2>
        </div>
        </div>
      <div className={`${styles.heading}`}>
        <h1>Browse by category</h1>
      </div>

      <div className="container mx-auto p-4">
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
    <div className="bg-white text-black rounded-lg flex flex-col items-center border border-black"
         style={{ maxWidth: '10rem', margin: '0.5rem' }}>
      <img src={Flour} alt="Flour" className="w-12 h-12 mb-2" />
      <p className="text-base font-semibold">Flour</p>
    </div>
    <div className="bg-white text-black rounded-lg flex flex-col items-center border border-black"
         style={{ maxWidth: '10rem', margin: '0.5rem' }}>
      <img src={Grains} alt="Grains" className="w-12 h-12 mb-2" />
      <p className="text-base font-semibold">Grains</p>
    </div>
    <div className="bg-white text-black rounded-lg flex flex-col items-center border border-black"
         style={{ maxWidth: '10rem', margin: '0.5rem' }}>
      <img src={Oil} alt="Oils" className="w-12 h-12 mb-2" />
      <p className="text-base font-semibold">Oils</p>
    </div>
    <div className="bg-red-700 text-white rounded-lg flex flex-col items-center"
         style={{ maxWidth: '10rem', margin: '0.5rem' }}>
      <img src={Beauty} alt="Beauty & Cosmetics" className="w-12 h-12 mb-2" />
      <p className="text-base font-semibold">Beauty & Cosmetics</p>
    </div>
  </div>
</div>



    </div>
  );
}

export default Categories;
