import React from "react";
import styles from "../../../Styles/Style";
import { motion } from "framer-motion";

const CheckoutSteps = ({ active }) => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full justify-center flex items-center flex-wrap">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.6 }}
          className={`${styles.noramlFlex}`}
        >
          <div className={`${styles.cart_button} ${active >= 1 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}>
            <span className={`${styles.cart_button_text} ${active >= 1 ? 'text-white' : 'text-[#4A0000]'}`}>
              1.Shipping
            </span>
          </div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.6 }}
            className={`w-[30px] 800px:w-[70px] h-[4px] ${active >= 2 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -150 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -150 }}
          transition={{ duration: 0.6 }}
          className={`${styles.noramlFlex}`}
        >
          <div
            className={`${styles.cart_button} ${active >= 2 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          >
            <span className={`${styles.cart_button_text} ${active >= 2 ? 'text-white' : 'text-[#f63b60]'}`}>
              2.Delivery
            </span>
          </div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.6 }}
            className={`w-[30px] 800px:w-[70px] h-[4px] ${active >= 3 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.6 }}
          className={`${styles.noramlFlex}`}
        >
          <div
            className={`${styles.cart_button} ${active >= 3 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          >
            <span className={`${styles.cart_button_text} ${active >= 3 ? 'text-white' : 'text-[#f63b60]'}`}>
              3.Payment
            </span>
          </div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.6 }}
            className={`w-[30px] 800px:w-[70px] h-[4px] ${active >= 4 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.6 }}
          className={`${styles.noramlFlex}`}
        >
          <div
            className={`${styles.cart_button} ${active === 4 ? 'bg-[#4A0000]' : 'bg-[#FDE1E6]'}`}
          >
            <span className={`${styles.cart_button_text} ${active === 4 ? 'text-white' : 'text-[#f63b60]'}`}>
              4.Success
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CheckoutSteps;
