import React from "react";
import {Link} from "react-router-dom";

const ProductListing = () => {
  return (
    <div className="">
      {/* Header */}
      <header className="w-full">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Product Description</h1>
        </div>
      </header>

      {/* Main content */}
      <main className="flex-grow w-full flex justify-between bg-gray-200">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:space-x-8 w-full">
          {/* Left section for image upload */}
          <div className="w-full  bg-white p-6 shadow rounded-lg mb-8 lg:mb-0 flex flex-col">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Upload Photos</h2>
            <div className="border-dashed border-2 border-gray-300 p-6 flex flex-col items-center justify-center flex-grow">
              <div className="text-center">
                <p className="text-gray-600">
                  Drop your image here, or <span className="text-blue-500 cursor-pointer">browse</span>
                </p>
                <p className="text-gray-400 mt-2">Supports: JPG, PNG, GIF, WEBP</p>
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="imageURL" className="block text-sm font-medium text-gray-700">Import from URL</label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="imageURL"
                  id="imageURL"
                  className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                  placeholder="Add file URL"
                />
                <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-r-md">Upload</button>
              </div>
            </div>
            <div className="mt-4 text-right">
              <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md">Cancel</button>
              <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 ml-2 rounded-md">Done</button>
            </div>
          </div>

          {/* Right section for product details */}
          <div className="w-full  bg-white p-6 shadow rounded-lg flex flex-col">
            <div className="space-y-6 flex-grow">
              <div>
                <label htmlFor="productName" className="block text-sm font-medium text-gray-700">Product Name</label>
                <input
                  type="text"
                  name="productName"
                  id="productName"
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="productDescription" className="block text-sm font-medium text-gray-700">Enter product description...</label>
                <textarea
                  name="productDescription"
                  id="productDescription"
                  rows="4"
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <div>
                <label htmlFor="productCost" className="block text-sm font-medium text-gray-700">Product Cost</label>
                <div className="flex space-x-4">
                <div className="w-1/2">
                  <input
                    type="text"
                    name="minCost"
                    id="minCost"
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-800 rounded-md"
                    placeholder="Minimum Cost"
                  />
                </div>
                <div className="w-1/2">
                  <input
                    type="text"
                    name="maxCost"
                    id="maxCost"
                    className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="Maximum Cost"
                  />
                </div>
              </div>
              </div>
              <div>
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
                <select
                  name="location"
                  id="location"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option>Country</option>
                  <option>Botswana</option>
                  <option>Ghana</option>
                  <option>Nigeria</option>
                </select>
              </div>

              <Link to={"/market-insights"} className="inline-block hover:opacity-60 border-b border-white ml-auto 800px:mr-0">
                <div className={`mt-8`}>
                    <span className="bg-red-500 hover:bg-red-500 hover:opacity-40 transition-opacity duration-300 px-6 py-2 text-white font-bold rounded">
                     Submit
                    </span>
                </div>
             </Link>
            </div>
          </div>
        </div>
      </main>

  
    </div>
  );
};

export default ProductListing;
