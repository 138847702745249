import React, { useState } from 'react';
import ProgressBar from './ProgressBar';

const ProgressChecker = () => {
    const [progress] = useState(0); // Initial progress set to 0%

    return (
        <div className="p-4">
            <h1 className="text-3xl font-bold text-center mb-4">EXPORT READINESS CHECKER | GENERAL</h1>
            <ProgressBar progress={progress} />
            
        </div>
    );
};

export default ProgressChecker;
