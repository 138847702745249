import React from "react";

import logo from "../../assets/logo.png";

import {
  AiOutlineTwitter,
  AiFillGithub,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-footer-image bg-cover bg-center h-150 w-full text-white ">  
      <div className="flex flex-wrap justify-between sm:px-20 px-5 py-10 sm:text-center">
        <ul className="flex flex-col sm:block px-5 text-center sm:text-start items-center">
         
          <h2 className="mt-3 font-semibold text-2xl">Subscribe</h2>
          <p className="mt-3">Get 10% of your first order</p>
          <div className="flex items-center mt-3">
            <input
              type="email"
              name="email"
              id="email"
              required
              placeholder="Enter your email"
              className="w-full py-2 px-4 text-white-800 bg-transparent border-2 border-white rounded focus:outline-none hover:border-white hover:shadow-outline"
            />
            <button
              type="submit"
              className="ml-2 bg-transparent text-white p-2 rounded hover:bg-white hover:text-black"
              aria-label="Send"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-send-fill"
                viewBox="0 0 16 16"
                style={{ float: "right" }}
              >
                <path d="M0 15.5l16-7.5-16-7.5v6l12 1.5-12 1.5v6z" />
              </svg>
            </button>
          </div>
        </ul>

        <ul className="text-center sm:text-start mb-2 mt-3">
          <h1 className="text-semibold mb-7 text-xl">Support</h1>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Mest Alungutugui Street Accra
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            info@kuuza.co
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            +233 535299262
          </li>
        </ul>

        <ul className="text-center sm:text-start mb-2 mt-3">
          <h1 className="text-semibold mb-7 text-xl">Account</h1>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            My account
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            <Link to="/login-seller">Login / Register</Link>
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Cart
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Shop
          </li>
        </ul>

        <ul className="text-center sm:text-start mb-2 mt-3">
          <h1 className="text-semibold mb-7 text-xl">Quick Link</h1>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Privacy Policy
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Terms of use
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            <Link to="/faq">FAQ</Link>
          </li>
          <li className="hover:text-[#4A0000] cursor-pointer mb-3 duration-300">
            Contacts
          </li>
        </ul>

        <ul className="flex mt-3">
          <a href="#" target="_blank">
            <AiFillFacebook size={25} className="cursor-pointer" />
          </a>
          <a href="#" target="_blank">
            <AiOutlineTwitter size={25} className="ml-[15px] cursor-pointer" />
          </a>
          <a href="#" target="_blank">
            <AiFillGithub size={25} className="ml-[15px] cursor-pointer" />
          </a>
          <a href="https://www.instagram.com/kuuza.co/" target="_blank">
            <AiFillInstagram size={25} className="ml-[15px] cursor-pointer" />
          </a>
        </ul>
      </div>
      <hr style={{ marginTop: '20px', marginBottom: '20px', borderTop: '1px solid gray', width: '100%' }} />

      <div className="flex items-center justify-center pb-[20px]">
        <span className="text-white">
          &copy; Copyright{" "}
          <span className="text-[#4A0000] font-bold cursor-pointer">
            Kuuza
          </span>{" "}
          2024 . All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
