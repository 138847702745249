import React, { useState } from "react";
import Header from "../Components/Layout/Header";
import styles from "../Styles/Style";
import Footer from "../Components/Layout/Footer"
import DashboardSidebar from "../Components/Layout/Dashboard/DashboardSidebar";
import { motion } from "framer-motion";
import MarketInsights from "../Components/Layout/MarketInsights";
import ProfileSideBar from "../Components/Layout/ProfileSideBar";

const MarketInsightsPage = () => {

  return (
    <>
      <Header />
      <div className="flex w-full">
        <div className="w-[80px] 800px:w-[335px]">
          <ProfileSideBar active={8} />
        </div>

        <div className="w-full mt-10 px-5 ">
          <MarketInsights />
        </div>
      </div>
    
    </>
  );
};

export default MarketInsightsPage;
