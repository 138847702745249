import React from 'react';

import OrderProgressBar from './OrderProgressBar';

const PaymentMethods = () => {
  return (
  
  <div className="mt-4 bg-white p-6 rounded shadow-md">
    <OrderProgressBar />
    <div className="text-blue-500">Delivery Options</div>
    <div className="mt-2">
      <div className="flex items-center">
        <input
          type="radio"
          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
        />
        <div className="ml-3 text-gray-700">Direct Bank Transfer</div>
        <div className="ml-auto text-green-500 font-medium">FREE</div>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="radio"
          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
        />
        <div className="ml-3 text-gray-700">Other Payment Methods</div>
        <div className="ml-auto text-gray-700 font-medium">BWP800</div>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="radio"
          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
        />
        <div className="ml-3 text-gray-700">Same day delivery</div>
        <div className="ml-auto text-gray-700 font-medium">BWP9000</div>
      </div>
    </div>
    <div className="flex justify-between mt-4">
      <button className="px-4 py-2 bg-white text-red-500 rounded">
        Back
      </button>
      <button className="px-4 py-2 bg-red-500 text-white rounded">
        Continue
      </button>
    </div>
  </div>
);
};

export default PaymentMethods;