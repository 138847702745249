import React from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import { motion } from "framer-motion";
import ExportReadinessChecker from "../Components/Layout/ExportReadinessChecker";
import ProgressChecker from "../Components/Layout/ProgressChecker";

const ExportReadinessTestPage = () => {
  return (
    <>
      <Header activeHeading={1} />
      <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        transition={{ duration: 0.5 }}>
        <ProgressChecker />
        <ExportReadinessChecker />
        <Footer />
      </motion.div>
    </>
  );
};

export default ExportReadinessTestPage;
