import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { motion } from "framer-motion";

const AllOrders = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await fetch(apiUrl + "/api/orders/list/", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      const data = await response.json();
      setOrders(data);
    };

    fetchOrders();
  }, []);

  const columns = [
    {
      field: "productTitle",
      headerName: "Product Title",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "buyerCompanyName",
      headerName: "Buyer's Company Name",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "buyerCity",
      headerName: "Buyer's City",
      minWidth: 150,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "buyerEmail",
      headerName: "Buyer's Email",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={`/seller/order/${params.id}`}>
            <Button>
              <AiOutlineArrowRight size={20} className="cursor-pointer hover:text-[red] transition-colors" />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = orders.map(order => ({
    id: order.id,
    productTitle: order.product_details.title,
    amount: order.amount,
    unit: order.product_details.unit,
    buyerCompanyName: order.buyer_details.company_name,
    buyerCity: order.buyer_details.city,
    buyerEmail: order.buyer_details.email,
  }));

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}
      className="w-full">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </motion.div>
  );
};

export default AllOrders;