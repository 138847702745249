import React from "react";
import styles from "../../Styles/Style";
import { Link } from "react-router-dom";

const Navbar = ({ active, isUserLoggedIn, vertical }) => {
  const navClass = vertical
    ? "flex flex-col items-start space-y-2"
    : "flex space-x-4";

  return (
    <div className={`block 800px:${styles.noramlFlex} ${navClass}`}>
      <div className="flex">
        <span
          className={`${
            active === 1 ? "text-[#A52B0E]" : "text-black 800px:text-[#000000]"
          } font-[500] px-4 cursor-pointer mb-0 800px:mb-0`} 
        >
          <Link to={"/"}>Home</Link>
        </span>
        <span className="text-black 800px:text-[#000000] font-[500] px-6 cursor-pointer mb-0 800px:mb-0 hover:text-[#4A0000]">
          <Link to={'/contact'}>Contact</Link>
        </span>
        <span className="text-black 800px:text-[#000000] font-[500] px-6 cursor-pointer mb-0 800px:mb-0 hover:text-[#4A0000]">
          <Link to={'/about'}>About</Link>
        </span>
        <span className="text-black 800px:text-[#000000] font-[500] px-6 cursor-pointer mb-0 800px:mb-0 hover:text-[#4A0000]">
          <Link to={'/trade'}>Shop</Link>
        </span>
        {!isUserLoggedIn && (
          <>
            <span className="text-black 800px:text-[#000000] font-[500] px-6 cursor-pointer mb-0 800px:mb-0 hover:text-[#4A0000]">
              <Link to={'/sign-up'}>Sign up</Link>
            </span>
            {/* <span className="text-black 800px:text-[#000000] font-[500] px-6 cursor-pointer mb-0 800px:mb-0 hover:text-[#4A0000]">
              <Link to={'/login-seller'}>Login</Link>
            </span> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;