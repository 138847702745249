import React from "react";
import { motion } from "framer-motion";
import ProgressBar from "./ProgressBar";

const MyPayments = () => {
  return (
    <div className="w-full px-5">
        <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        className="pl-8 pt-1">
    </motion.div>
      <h1 className="text-2xl font-bold mb-4">Invoice: #27346733-022</h1>
      <div className="flex justify-start mb-4">
      <button className="mr-2 px-4 py-2 bg-white text-black rounded border border-gray-400">Invoice</button>
      <button className="px-4 py-2 bg-purple-600 text-white rounded">Track payment</button>
      </div>
      <ProgressBar />
      <div className="mb-8">
        <div className="flex justify-between mb-2">
          <div>
            <div>Invoice Date</div>
            <div className="font-bold">16 July, 2024</div>
          </div>
          <div>
            <div>Due Date</div>
            <div className="font-bold">16 July, 2024</div>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <div>Billed To</div>
            <div className="font-bold">Laila Givens</div>
            <div>Shea Creations</div>
            <div>9029 Tema, Ghana</div>
            <div>(+233) 521-912-161</div>
          </div>
          <div>
            <div>From</div>
            <div className="font-bold">Grace Oliver</div>
            <div>goliver@gmail.com</div>
            <div>Gaborone, Botswana</div>
            <div>(+267) 713-024-08</div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="border-b border-gray-700 pb-2">Description</th>
              <th className="border-b border-gray-700 pb-2">Quantity</th>
              <th className="border-b border-gray-700 pb-2">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pt-2">Raw Shea Butter</td>
              <td className="pt-2">1</td>
              <td className="pt-2">GH₵2170.23</td>
            </tr>
            <tr>
              <td className="pt-2">Unrefined 1 ton shea butter</td>
              <td className="pt-2"></td>
              <td className="pt-2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-right">
        <div className="mb-2">Subtotal: GH₵2170.23</div>
        <div className="mb-2">Total excluding tax: 0</div>
        <div className="font-bold text-xl">Total: GH₵2170.23</div>
        <div className="font-bold text-xl">Amount Due: GH₵2170.23</div>
      </div>
    </div>
  );
};

export default MyPayments;
