import React from "react";
import styles from "../../Styles/Style";
import { Link } from "react-router-dom";

const GetStarted = () => {
  return (
    <>
      
      <div className={`${styles.section} mt-[70px]`}>
        <div className="flex justify-between items-center mb-[20px]">
          <div className="flex items-center">
            <div className="w-5 h-7 bg-[#4A0000] mr-2.5 rounded-sm"></div>
            <h2 className="text-xl font-semibold text-[#4A0000]">Get Started</h2>
          </div>
        </div>
        <div className={`${styles.heading}`}>
          <h1>How it works</h1>
        </div>

        {/* Flex container for video and text */}
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          <div className="md:mb-0">
          <iframe src="https://fast.wistia.net/embed/iframe/s8vq28s6yq?seo=true&videoFoam=false" title="Kuuza Demo Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="700" height="394"></iframe>
          <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
          </div>

          {/* Container for heading and paragraph */}
          <div className="md:mt-0md:w-1/2 ">
            <div className={`${styles.heading}`}>
              <h1>Want to start sourcing locally <br />
              made African products?</h1>
            </div>
            <p className="pt-5 font-[400] font-Poppins text-[16px] text-[#000000] text-left">
            We make sourcing  food products easy, low-cost <br />
            and transparent.
            </p>
            <div className="mt-8">
              <div className="flex justify-center md:justify-start">
                <Link
                  className="bg-[#4A0000] hover:bg-red-500 hover:opacity-40 transition-opacity duration-300 px-6 py-2 text-white font-bold rounded"
                  to={'/products'}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ margin: '50px 0', borderTop: '1px solid gray', width: '100%' }} />
      </div>
    </>
  );
};

export default GetStarted;
