import React, { useState, useEffect } from 'react';

const InquiryList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        
        const response = await fetch(apiUrl + 'https://api.example.com/inquiries'); // Replace with your API endpoint
        setInquiries(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    

    fetchInquiries();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading inquiries: {error.message}</p>;

  return (
    <div className="p-4 w-full sm:w-3/4 lg:w-4/5">
      <h2 className="text-2xl font-bold mb-4">Inquiries</h2>
      <div className="border rounded-lg p-4">
        {inquiries.map(inquiry => (
          <div key={inquiry.id} className="flex justify-between items-center border-b py-4">
            <div className="w-1/3">{inquiry.title}</div>
            <div className="w-1/3">{inquiry.category}</div>
            <div className="w-1/3 flex justify-end">
              <button className="bg-[#A52B0E] text-white px-4 py-2 rounded">View</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InquiryList;
