import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Kuuza from "../../../assets/logo.png";
import avatar from "../../../assets/avatar.jpg";
import { FaHome } from "react-icons/fa";

const DashboardHeader = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [sellerInfo, setSellerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerInfo = async () => {
      try {
        const response = await fetch(apiUrl + "/api/accounts/sellers/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch seller details");
        }
        const data = await response.json();
        setSellerInfo(data);
      } catch (error) {
        console.error("Error fetching seller details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellerInfo();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(apiUrl + "/api/accounts/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem("access_token");
        navigate("/login-seller");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!sellerInfo) {
    return <div>Failed to load seller details.</div>;
  }

  const { full_name, passport_photo, id } = sellerInfo;

  return (
    <>
      <div className="w-full h-[60px] bg-white shadow-md sticky top-0 left-0 flex items-center justify-between px-4 z-50 md:h-[80px]">
        <Link to={"/"}>
          <img src={Kuuza} alt="Logo" className="w-[150px] h-[60px] md:w-[180px] md:h-[80px]" />
        </Link>

        <div className="flex items-center">

          <div className="800px:flex hidden items-center mr-4">
            <Link to="/">
              <FaHome
                size={24}
                title="Accueil"
                className="mx-2 cursor-pointer"
                color="#4c4c4c"
              />
            </Link>
          </div>

          <div className="relative" ref={dropdownRef}>
            <img
              src={passport_photo ? passport_photo : avatar}
              alt="seller/image"
              title={full_name}
              className="w-[40px] h-[40px] object-cover cursor-pointer border border-[#3957db] rounded-full mr-3 md:w-[50px] md:h-[50px]"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            />

            {isDropdownOpen && (
              <div className="absolute top-full right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg shadow-b shadow-r">
                <div className="p-4">
                  <div className="font-bold">{full_name}</div>
                  <button 
                  onClick={handleLogout}
                    className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 hover:text-[#e8e8e8]">
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;