import React from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import ProductListing from "../Components/Layout/ProductListing";


const ProductListingPage = () => {

  return (
    <>
      <Header />
      <div className="flex-grow container mx-auto py-12 px-6 flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="w-full">
          <ProductListing />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductListingPage;
