import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ToggleBar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const navigate = useNavigate();

  const toggleHandler = () => {
    setIsToggled(!isToggled);
    navigate(isToggled ? "/signup-buyer" : "/signup-seller");
  };

  return (
    <div className="flex items-center">
      <button
        className={`${
          isToggled ? "bg-gray-300" : "bg-[#4A0000]"
        } text-white px-6 py-3 rounded-l-lg focus:outline-none`}
        onClick={toggleHandler}
      >
        {isToggled ? "Exporter" : "Importer"}
      </button>
      <button
        className={`${
          isToggled ? "bg-[#4A0000]" : "bg-gray-300"
        } text-white px-6 py-3 rounded-r-lg focus:outline-none`}
        onClick={toggleHandler}
      >
        {isToggled ? "Importer" : "Exporter"}
      </button>
    </div>
  );
};

export default ToggleBar;