import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { RxPerson } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineLogout, AiOutlineMessage, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaUser, FaAddressBook, FaCreditCard, FaHistory, FaTruck } from "react-icons/fa";
import { ToastContainer } from "react-toastify";

const BuyerSideBar = ({ active, setActive }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const handleExpand = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(apiUrl + "/api/accounts/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
  
      if (response.ok) {
        localStorage.removeItem("access_token");
        navigate("/login-seller");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className={`p-4 z-30 pt-8 bg-white shadow-sm h-full transition-all duration-300 md:w-64 ${isSidebarExpanded ? 'w-1/2' : 'w-16'}`}>
      {/* <div className="flex justify-end mb-4 md:hidden">
        <button onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}>
          {isSidebarExpanded ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </button>
      </div> */}

      <div
        className="flex items-center mb-8 w-full cursor-pointer"
        onClick={() => handleExpand("manageAccount")}
      >
        <RxPerson size={20} color={expandedSection === "manageAccount" ? "#e64a07" : ""} />
        <span className={`pl-3 ${expandedSection === "manageAccount" ? "text-[#e64a07]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
          Manage My Account
        </span>
      </div>

      {expandedSection === "manageAccount" && (
        <div className="pl-6">
          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(1)}
          >
            <FaUser size={20} color={active === 1 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 1 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              My Profile
            </span>
          </div>

          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(2)}
          >
            <FaAddressBook size={20} color={active === 2 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 2 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              Address Book
            </span>
          </div>

          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(3)}
          >
            <FaCreditCard size={20} color={active === 3 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 3 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              My Payments
            </span>
          </div>
        </div>
      )}

      <div
        className="flex items-center mb-8 w-full cursor-pointer"
        onClick={() => handleExpand("orders")}
      >
        <HiOutlineShoppingBag size={20} color={expandedSection === "orders" ? "#e64a07" : ""} />
        <span className={`pl-3 ${expandedSection === "orders" ? "text-[#e64a07]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
         My Orders
        </span>
      </div>

      {expandedSection === "orders" && (
        <div className="pl-6">
          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(4)}
          >
            <FaHistory size={20} color={active === 4 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 4 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              Current Orders
            </span>
          </div>

          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(5)}
          >
            <FaHistory size={20} color={active === 5 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 5 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              Order History
            </span>
          </div>

          <div
            className="flex items-center mb-8 w-full cursor-pointer"
            onClick={() => setActive(6)}
          >
            <FaTruck size={20} color={active === 6 ? "#e64a07" : "#555"} />
            <span className={`pl-3 ${active === 6 ? "text-[#e64a07]" : "text-[#555]"} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
              Track Orders
            </span>
          </div>
        </div>
      )}

      <div
        className="flex items-center mb-8 w-full cursor-pointer"
        onClick={() => handleExpand("products")}
      >
        <HiOutlineShoppingBag size={20} color={active === 7 ? "#e64a07" : ""} />
        <span className={`pl-3 ${active === 7 ? "text-[#e64a07]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
          Inquiries
        </span>
      </div>

      <div
        className="flex items-center mb-8 w-full cursor-pointer"
        onClick={() => { setActive(11); navigate("/inbox"); }}
      >
        <AiOutlineMessage size={20} color={active === 8 ? "#e64a07" : ""} />
        <span className={`pl-3 ${active === 8 ? "text-[#e64a07]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
          Inbox
        </span>
      </div>

      <div
        className="flex items-center mb-8 w-full cursor-pointer"
        onClick={handleLogout}
      >
        <AiOutlineLogout size={20} color={active === 9 ? "#e64a07" : ""} />
        <span className={`pl-3 ${active === 9 ? "text-[#e64a07]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block text-[14px] font-[400]`}>
          Logout
        </span>
      </div>

      {/* toast message */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

export default BuyerSideBar;