import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineTrackChanges, MdDelete, MdEdit } from "react-icons/md";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { motion } from "framer-motion";

const ProductCategory = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);

  const UNIT_TYPES = [
    { value: "KG", label: "Kilogram" },
    { value: "LITRE", label: "Litre" },
    { value: "UNIT", label: "Unit" },
    { value: "PIECE", label: "Piece" },
    { value: "METRE", label: "Metre" },
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(apiUrl + "/api/product/sellers/me/products/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error("Unauthorized access. Please log in again.");
          }
          throw new Error("Network response was not ok");
        }
        const products = await response.json();
        if (!Array.isArray(products)) {
          throw new Error("Invalid response format. Expected an array.");
        }
        const formattedProducts = products.map((product) => ({
          id: product.id,
          title: product.title,
          quantity: product.quantity,
          price: product.price,
          currency: product.currency,
          unit: product.unit,
          is_available: product.is_available ? "Yes" : "No",
        }));
        setRows(formattedProducts);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    fetchProducts();
  }, [apiUrl]);

  const handleDelete = (product) => {
    setProductToDelete(product);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await fetch(apiUrl + `/api/product/delete/${productToDelete.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setRows(rows.filter((row) => row.id !== productToDelete.id));
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteConfirmOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(apiUrl + `/api/product/edit/${selectedProduct.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(selectedProduct),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const updatedProduct = await response.json();
      setRows(rows.map((row) => (row.id === updatedProduct.id ? updatedProduct : row)));
      setOpen(false);
    } catch (error) {
      console.error("Error updating product:", error.message);
    }
  };

  const columns = [
    { field: "title", headerName: "Title", minWidth: 150, flex: 0.7 },
    { field: "quantity", headerName: "Quantity", minWidth: 130, flex: 0.7 },
    { field: "price", headerName: "Price", minWidth: 130, flex: 0.7 },
    { field: "currency", headerName: "Currency", minWidth: 130, flex: 0.7 },
    { field: "unit", headerName: "Unit", minWidth: 130, flex: 0.7 },
    {
      field: "is_available",
      headerName: "Available",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleEdit(params.row)}>
              <MdEdit size={20} className="cursor-pointer hover:text-[#4a90e2] transition-colors" />
            </Button>
            <Button onClick={() => handleDelete(params.row)}>
              <MdDelete size={20} className="cursor-pointer hover:text-[#ff4a4a] transition-colors" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}
      className="pl-8 pt-1"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            value={selectedProduct?.title || ""}
            onChange={(e) => setSelectedProduct({ ...selectedProduct, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Quantity"
            fullWidth
            type="number"
            value={selectedProduct?.quantity || ""}
            onChange={(e) => setSelectedProduct({ ...selectedProduct, quantity: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Price"
            fullWidth
            type="number"
            value={selectedProduct?.price || ""}
            onChange={(e) => setSelectedProduct({ ...selectedProduct, price: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Currency"
            fullWidth
            value={selectedProduct?.currency || ""}
            onChange={(e) => setSelectedProduct({ ...selectedProduct, currency: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Unit</InputLabel>
            <Select
              value={selectedProduct?.unit || ""}
              onChange={(e) => setSelectedProduct({ ...selectedProduct, unit: e.target.value })}
            >
              {UNIT_TYPES.map((unit) => (
                <MenuItem key={unit.value} value={unit.value}>
                  {unit.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Available"
            fullWidth
            value={selectedProduct?.is_available || ""}
            onChange={(e) => setSelectedProduct({ ...selectedProduct, is_available: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteConfirmOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Confirm Delete</span>
            <Button onClick={handleClose} color="primary">
              <Close />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this product?
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>{productToDelete?.title}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default ProductCategory;