import React from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import Delivery from "../Components/Layout/Checkout page/Delivery";
import CheckoutSteps from "../Components/Layout/Checkout page/CheckoutSteps";

function DeliveryPage() {
  return (
    <>
      <Header />

      <br />
      <br />

      <CheckoutSteps active={2} />

      <Delivery />

      {/* <Footer /> */}
    </>
  );
}

export default DeliveryPage;
