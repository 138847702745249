import React from 'react';
import FacebookAdsCard from './FacebookAdsCard';
import { Link } from 'react-router-dom';
import BoostForm from './BoostForm';

const MarketInsights = () => {
  return (
    <div className="flex flex-col items-center w-full h-[80vh] shadow py-3 px-5 overflow-y-scroll">
      <h1 className="text-2xl font-bold">Market Insights</h1>
      <button className="mt-2 px-4 py-2 bg-red-600 text-white rounded-full">OUR SERVICES</button>
      
      <h2 className="mt-10 text-xl font-bold">Elevate Your Digital Presence Today</h2>
      <p className="mt-4 text-center max-w-2xl">
        Leverage on our digital marketing services and advertise on Facebook. Through our targeted ads,
        you can significantly increase your visibility, reach a wider audience, and effectively promote
        your products or services. It's an opportunity to level the playing field and compete with larger
        businesses in the digital marketplace.
      </p>


      
      <BoostForm />

      <Link to={"/dashboard-suggested-country"} className="hover:opacity-60">
           <span className="bg-red-500 hover:bg-red-500 hover:opacity-40 transition-opacity duration-300 px-6 py-2 text-white font-bold rounded">
             Cancel
           </span>
       </Link>
    </div>
  );
};

export default MarketInsights;
