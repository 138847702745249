import React from "react";

const ProgressBar = () => {
  return (
    <div className="mb-8">
      <div className="relative flex items-center">
        <div className="absolute inset-0 flex items-center justify-between">
          <div className="flex-[0.2] h-1 bg-green-500"></div>
          <div className="flex-[0.8] h-1 bg-gray-400"></div>
        </div>
        <div className="relative z-10 flex items-center justify-between w-full">
          <div className="flex flex-col items-center">
            <div className="bg-green-500 w-6 h-6 rounded-full flex items-center justify-center">
              <span className="text-white text-xs font-bold">1</span>
            </div>
            <div className="mt-4 text-xs text-black">Today at 9:45am</div>
            <div className="mt-1 text-xs text-gray-800">Transfer has been set up</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gray-400 w-6 h-6 rounded-full flex items-center justify-center">
              <span className="text-white text-xs font-bold">2</span>
            </div>
            <div className="mt-4 text-xs text-black">Today at 9:58am</div>
            <div className="mt-1 text-xs text-gray-800">We paid out your Ghana cedis</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gray-400 w-6 h-6 rounded-full flex items-center justify-center">
              <span className="text-white text-xs font-bold">3</span>
            </div>
            <div className="mt-2 text-xs text-gray-800">Payment received</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
