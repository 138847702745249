import React, {useState} from "react";
import ShopInbox from "../Components/Layout/Dashboard/DashboardContent/ShopInbox";
import DashboardHeader from "../Components/Layout/Dashboard/DashboardHeader";
import DashboardSidebar from "../Components/Layout/Dashboard/DashboardSidebar";
import ProfileSideBar from "../Components/Layout/ProfileSideBar";

const SellerMessagePage = () => {
  const [active, setActive] = useState(10);

  return (
    <>
      <DashboardHeader />

      <div className="flex w-full">
        <div className="w-[80px] 800px:w-[335px]">
          <ProfileSideBar active={active} setActive={setActive} />
        </div>

        <div className="w-full mt-10 px-5 ">
          <ShopInbox />
        </div>
      </div>
    </>
  );
};

export default SellerMessagePage;
