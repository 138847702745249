import React from "react";
import { Link } from "react-router-dom";
import Africa from "../../assets/green.png";

const Hero = () => {
  return (
    <div className="bg-custom-image bg-cover bg-center h-150 w-full flex flex-col 800px:flex-row items-start">
      <div className="w-full 800px:w-[80%] p-5 flex flex-col justify-start ">
        <h2 className="text-[28px] 800px:text-[52px] font-[700] capitalize text-[#000000] leading-[1.2] mb-6 800px:mb-10 mt-6 800px:mt-10 mr-10">
          <span className="text-[#4A0000]">Discover</span> the Best Deals <br />
          on African Products!
        </h2>

        <div className="flex flex-col justify-start items-start space-y-5">
          <p className="font-[400] font-Poppins text-[14px] 800px:text-[16px] text-[#000000] text-left mr-10">
            Searching for top-quality African products at unbeatable prices? <br />
            Your search ends here!
          </p>

          <Link to={"/signup-buyer"} className="mt-2">
            <div className="inline-block hover:opacity-90">
              <span className="py-2 px-2 font-semibold text-white bg-[#4A0000] rounded-md">
                <span >Get Started</span>
              </span>
            </div>
          </Link>
        </div>
      </div>
      <div
        className="w-full 800px:w-[50%] h-[10vh] 800px:h-full bg-no-repeat bg-right"
        style={{
          backgroundImage: `url(${Africa})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "370px",
          height: "350px",
        }}
      ></div>
    </div>
  );
};

export default Hero;
