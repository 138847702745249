import React from "react";

const DashboardProfileMain = () => {
  const cards = [
    {
      title: "Shea Butter",
      description:
        "I am looking to import 200 Kg of Shea Butter from Ghana to be shipped in Botswana before August 2nd ...",
      date: "Jul 24th 2024",
    },
    {
      title: "African Honey",
      description:
        "I need 50 liters of African Honey from Kenya for a new product line launching in September ...",
      date: "Jul 25th 2024",
    },
    {
      title: "Moringa Powder",
      description:
        "Seeking suppliers for 500 Kg of Moringa Powder to be delivered in Nigeria by mid-August ...",
      date: "Jul 26th 2024",
    },
    {
      title: "Baobab Oil",
      description:
        "In need of 100 liters of Baobab Oil from Tanzania to be shipped to South Africa before August 15th ...",
      date: "Jul 27th 2024",
    },
    {
      title: "Shea Butter",
      description:
        "Seeking suppliers for 500 Kg of Shea Butter to be delivered in Botswana by mid-August ...",
      date: "Jul 27th 2024",
    },
    {
      title: "Butter Oil",
      description:
        "In need of 100 liters of Butter Oil from Ghana to be shipped to Botswana before August 10th ...",
      date: "Jul 22th 2024",
    },
  ];

  return (
    <div style={{ marginLeft: '15px', marginRight: '15px' }}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {cards.map((card, index) => (
          <div
            key={index}
            className="relative border border-[#4A0000] p-6 w-full cursor-pointer transition-transform transform hover:shadow-lg hover:border-gray-400 rounded-lg"
            style={{
              transform: "perspective(1000px)",
              transition: "transform 0.2s ease-in-out",
              boxShadow: "0 4px 2px -2px #4A0000",
            }}
            onMouseMove={(e) => {
              const card = e.currentTarget;
              const rect = card.getBoundingClientRect();
              const x = e.clientX - rect.left - rect.width / 2;
              const y = e.clientY - rect.top - rect.height / 2;
              card.style.transform = `perspective(1000px) rotateX(${y / 10}deg) rotateY(${x / 10}deg)`;
            }}
            onMouseLeave={(e) => {
              const card = e.currentTarget;
              card.style.transform = "perspective(1000px) rotateX(0) rotateY(0)";
            }}
          >
            <h2 className="font-bold text-xl mb-2 text-center">{card.title}</h2>
            <p className="mb-4">{card.description}</p>
            <p className="absolute bottom-0 right-0 p-2 text-sm">{card.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardProfileMain;