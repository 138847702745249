import React from 'react';
import About from '../../assets/about.jpg';

const StorySection = () => {
  return (
    <section className="px-6 py-12 bg-white">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-6">
          <h1 className="text-3xl font-bold mb-6">Our Story</h1>
          <p className="text-lg leading-relaxed mb-6">
            Launched in 2024, inspired by the AfCFTA agreement to help MSMEs in Africa reach a wider market by offering them visibility, market insights and financial tools. We are an online marketplace.
            <br />
            <br />
            Deluxe has more than a thousand African products to offer, growing at a very fast pace. Exclusive offers a diverse assortment in categories ranging from consumer.
          </p>
        </div>
        <div className="md:w-1/2">
          <img src={About}
           alt="Our Story" 
           className="rounded-lg shadow-lg w-full" />
        </div>
      </div>
    </section>
  );
};

export default StorySection;
