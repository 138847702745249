import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDown } from "react-icons/ai";
import styles from "../../../Styles/Style";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import { ClipLoader } from "react-spinners";

const LoginSeller = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("Importer");

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
    }
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch(apiUrl + "/api/accounts/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        localStorage.setItem("refresh_token", data.refresh);
        localStorage.setItem("access_token", data.access);
  
        if (!localStorage.getItem("savedEmail") && !localStorage.getItem("savedPassword")) {
          localStorage.setItem("savedEmail", email);
          localStorage.setItem("savedPassword", password);
        }
  
        const userResponse = await fetch(`${apiUrl}/api/accounts/user/`, {
          headers: {
            'Authorization': `Bearer ${data.access}`,
          },
        });
  
        const userData = await userResponse.json();
  
        if (userResponse.ok) {
          localStorage.setItem("userDetails", JSON.stringify(userData));
  
          if (userType === "Importer" && userData.user_type === "Buyer") {
            navigate("/buyer-profile");
          } else if (userType === "Exporter" && userData.user_type === "Seller") {
            navigate("/profile");
          } else {
            if (userType === "Importer") {
              toast.error("Are you trying to sign in as a buyer?");
            } else if (userType === "Exporter") {
              toast.error("Are you trying to sign in as a seller?");
            }
          }
        } else {
          console.error('Failed to fetch user details');
        }
  
        toast.success("Login successful");
      } else {
        toast.error(data.detail || "Login failed");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col relative">
      <Header />
      <div className={`flex-grow bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 overflow-auto ${loading ? "opacity-60" : ""}`}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="text-center text-3xl font-extrabold text-gray-900">
            Login to your account
          </div>
        </div>
        <div className="mt-8 mx-auto w-[90%] 800px:w-[45%]">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleOnSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="accounttype"
                  className="block text-sm font-medium text-gray-700"
                >
                  Account Type
                </label>
                <div className="relative mt-1">
                  <select
                    name="accounttype"
                    id="accounttype"
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  >
                    <option value="Importer">Importer</option>
                    <option value="Exporter">Exporter</option>
                  </select>
                  <AiOutlineDown className="absolute right-3 top-3 pointer-events-none text-gray-400" />
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <Link
                    to="/seller/forget-password"
                    className="font-medium text-red-600 hover:text-red-500">
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700">
                  Submit
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Not have any account?</h4>
                <Link to="/signup-seller" className="text-red-600 pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
        />
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <ClipLoader color="#ffffff" size={50} />
        </div>
      )}
    </div>
  );
};

export default LoginSeller;