import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Kuuza from "../../../assets/logo.png";
import avatar from "../../../assets/img.png";
import {
  HiOutlineShoppingCart,
  HiOutlineBell
} from "react-icons/hi";

const BuyerHeader = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [buyerInfo, setBuyerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchBuyerInfo = async () => {
      try {
        const response = await fetch(apiUrl + "/api/accounts/buyer/profile/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch buyer details");
        }
        const data = await response.json();
        setBuyerInfo(data);
      } catch (error) {
        console.error("Error fetching buyer details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyerInfo();
  }, [apiUrl]);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.href = "/login-seller";
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!buyerInfo) {
    return <div>Failed to load buyer details.</div>;
  }

  const { full_name, email, passport_photo } = buyerInfo;

  return (
    <>
      <div className="w-full h-[60px] md:h-[80px] bg-white shadow-md sticky top-0 left-0 flex items-center justify-between px-4 z-50">
        <Link to={"/"}>
          <img src={Kuuza} alt="Logo" className="w-[120px] h-[60px] md:w-[160px] md:h-[80px]" />
        </Link>

        <div className="flex items-center">
          <div className="800px:flex hidden items-center mr-4">
            <Link to="/" className="hover:text-[#4A0000] mr-4">Home</Link>
            <Link to="/products" className="hover:text-[#4A0000]">Products</Link>
          </div>

          <div className="800px:flex hidden items-center mr-4">
            <Link to="/dashboard-products">
              <HiOutlineShoppingCart
                size={24}
                title="Cart"
                className="mx-2 cursor-pointer"
                color="#4c4c4c"
              />
            </Link>
          </div>

          <div className="800px:flex hidden items-center mr-4">
            <Link to="/dashboard-orders">
              <HiOutlineBell
                size={24}
                title="Notification"
                className="mx-2 cursor-pointer"
                color="#4c4c4c"
              />
            </Link>
          </div>

          <div className="relative">
            <img
              src={passport_photo ? passport_photo : avatar}
              alt="buyer/image"
              title={full_name}
              className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] object-cover cursor-pointer border border-[#3957db] rounded-full mr-3"
              onClick={toggleDropdown}
            />
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                <div className="p-4">
                  <p className="font-semibold">{full_name}</p>
                  <p className="text-sm text-gray-500">{email}</p>
                </div>
                <div className="border-t border-gray-200">
                  <button
                    className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerHeader;