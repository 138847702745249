import React from 'react';
import { FiMail, FiPhoneCall } from "react-icons/fi";

const Contact = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-start">
        <div className="w-full md:w-1/3 p-4 bg-white shadow-md rounded-md mb-4 md:mb-0">
          <div className="mb-4 flex items-start">
            <span className="text-red-500 mt-1 mr-2">
              <FiPhoneCall size={20} color={"DB4444"} />
            </span>
            <div>
              <span className="font-bold text-lg">Call Us</span>
              <p className="text-gray-700 mb-1">We are available 24/7, 7 days a week.</p>
              <p className="text-gray-700">Phone: +233 535299162</p>
            </div>
          </div>
          <div className="mb-4 flex items-start">
            <span className="text-red-500 mt-1 mr-2">
              <FiMail size={20} color={"DB4444"} />
            </span>
            <div>
              <span className="font-bold text-lg">Write To Us</span>
              <p className="text-gray-700 mb-1">Fill out our form and we will contact you within 24 hours.</p>
              <p className="text-gray-700">Emails: info@kuuza.co</p>
            </div>
          </div>
        </div>

        <div className="h-full md:w-2/3 p-4 bg-white shadow-md rounded-md md:ml-4">
          <form className="h-full">
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                <input
                  type="text"
                  placeholder="Your Name *"
                  className="w-full p-3 border border-gray-300 rounded-md"
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
                <input
                  type="email"
                  placeholder="Your Email *"
                  className="w-full p-3 border border-gray-300 rounded-md"
                />
              </div>
              <div className="w-full md:w-1/3 px-2">
                <input
                  type="tel"
                  placeholder="Your Phone *"
                  className="w-full p-3 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="mb-4">
              <textarea
                placeholder="Your Message"
                className="w-full p-3 border border-gray-300 rounded-md h-32"
              ></textarea>
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-red-500 text-white font-bold rounded-md hover:bg-red-600"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
