import React from "react";
import styles from "../../Styles/Style";

function Values() {
  return (
    <>
    <div
      className={`hidden sm:flex sm:justify-center sm:items-center mb-12 py-10 px-5 cursor-pointer rounded-xl`}
    >
      <div className="flex items-center gap-3 sm:gap-10 justify-between">
        <div className="flex justify-between items-start space-x-10">
          <div className="text-center">
            <div className="w-20 h-20 rounded-full bg-[#4A0000] flex items-center justify-center mx-auto">
              <div className="bg-black w-16 h-16 rounded-full flex items-center justify-center m-2.5">
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h18l-3 9H6l-3-9zm0 0l-1-2m19 2l1-2m-10 9.5h6m-6 0a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5zm6 0a2.5 2.5 0 102.5-2.5 2.5 2.5 0 00-2.5 2.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <h2 className="mt-3 text-xl font-bold text-[#4A0000]">Market Access</h2>
            <p>Connecting African MSME with AfCFTA</p>
          </div>
          <div className="text-center">
            <div className="w-20 h-20 rounded-full bg-[#4A0000] flex items-center justify-center mx-auto">
            <div className="bg-black w-16 h-16 rounded-full flex items-center justify-center m-2.5">
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h18l-3 9H6l-3-9zm0 0l-1-2m19 2l1-2m-10 9.5h6m-6 0a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5zm6 0a2.5 2.5 0 102.5-2.5 2.5 2.5 0 00-2.5 2.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <h2 className="mt-3 text-xl font-bold text-[#4A0000]">24/7 Customer Service</h2>
            <p>Friendly 24/7 customer support</p>
          </div>
          <div className="text-center">
            <div className="w-20 h-20 rounded-full bg-[#4A0000] flex items-center justify-center mx-auto">
            <div className="bg-black w-16 h-16 rounded-full flex items-center justify-center m-2.5">
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h18l-3 9H6l-3-9zm0 0l-1-2m19 2l1-2m-10 9.5h6m-6 0a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5zm6 0a2.5 2.5 0 102.5-2.5 2.5 2.5 0 00-2.5 2.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <h2 className="mt-3 text-xl font-bold text-[#4A0000]">Local Currency Payments</h2>
            <p>Be able to transact in your local currency</p>
          </div>
        </div>
      </div>
    </div>
    <div className={`fixed bottom-5 right-5 ${window.pageYOffset > 0 ? 'block' : 'hidden'}`}>
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="bg-gray-300 p-2 rounded-full cursor-pointer hover:opacity-80 mx-2"
        aria-label="Scroll to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
        </svg>
      </button>
    </div>
    </>
  );
}

export default Values;
