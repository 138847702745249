import React from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import OrderSummary from "../Components/Layout/OrderSummary";
import ShippingForm from "../Components/Layout/ShippingForm";
import DeliveryOptions from "../Components/Layout/DeliveryOptions";
import PaymentMethods from "../Components/Layout/PaymentMethods";
import ProcessRing from "../Components/Layout/ProcessRing";

const OrderDetailsPage = () => {
  const [progress, setProgress] = React.useState(0);

  // Simulate progress increment
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 10);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [progress]);
  return (
    <>
      <Header />
      <div className="flex-grow container mx-auto py-12 px-6 flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="w-full lg:w-7/12">
          <OrderSummary />
        </div>
        <div className="w-full lg:w-5/12">
        <PaymentMethods />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetailsPage;
