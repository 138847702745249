import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { RxPerson } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineLogout, AiOutlineMessage, AiOutlineShop, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaUser, FaAddressBook, FaCreditCard, FaHistory, FaTruck, FaChartLine, FaBoxOpen, FaPlus } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

const ProfileSideBar = ({ active, setActive }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const handleExpand = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(apiUrl + "/api/accounts/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
  
      if (response.ok) {
        localStorage.removeItem("access_token");
        navigate("/login-seller");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className={`p-4 z-30 pt-8 bg-white shadow-sm h-full transition-all duration-300 md:w-64 ${isSidebarExpanded ? 'w-1/2' : 'w-16'}`}>
      <div className="flex justify-end mb-4 md:hidden">
        <button onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}>
          {isSidebarExpanded ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </button>
      </div>

      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={() => handleExpand("manageAccount")}
      >
        <RxPerson size={20} color={expandedSection === "manageAccount" ? "#4A0000" : ""} />
        <span className={`pl-3 ${expandedSection === "manageAccount" ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          Manage My Account
        </span>
      </div>

      {expandedSection === "manageAccount" && (
        <div className="pl-6">
          {[1, 2, 3, 4].map(num => (
            <div
              key={num}
              className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
              onClick={() => setActive(num)}
            >
              {num === 1 ? <FaUser size={20} color={active === num ? "#4A0000" : ""} /> : 
               num === 2 ? <FaAddressBook size={20} color={active === num ? "#4A0000" : ""} /> : 
               num === 3 ? <AiOutlineMessage size={20} color={active === num ? "#4A0000" : ""} /> : 
               <FaCreditCard size={20} color={active === num ? "#4A0000" : ""} />}
              <span className={`pl-3 ${active === num ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
                {num === 1 ? "Main Dashboard" : num === 2 ? "My Profile" : num === 3 ? "Enquiries" : "My Payments"}
              </span>
            </div>
          ))}
        </div>
      )}

      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={() => handleExpand("orders")}
      >
        <HiOutlineShoppingBag size={20} color={expandedSection === "orders" ? "#4A0000" : ""} />
        <span className={`pl-3 ${expandedSection === "orders" ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          Orders
        </span>
      </div>

      {expandedSection === "orders" && (
        <div className="pl-6">
          {[5, 6, 7].map(num => (
            <div
              key={num}
              className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
              onClick={() => setActive(num)}
            >
              {num === 5 ? <FaHistory size={20} color={active === num ? "#4A0000" : ""} /> : 
               num === 6 ? <FaHistory size={20} color={active === num ? "#4A0000" : ""} /> : 
               <FaTruck size={20} color={active === num ? "#4A0000" : ""} />}
              <span className={`pl-3 ${active === num ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
                {num === 5 ? "Current Orders" : num === 6 ? "Order History" : "Track Orders"}
              </span>
            </div>
          ))}
        </div>
      )}

      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={() => handleExpand("products")}
      >
        <HiOutlineShoppingBag size={20} color={expandedSection === "products" ? "#4A0000" : ""} />
        <span className={`pl-3 ${expandedSection === "products" ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          My Products
        </span>
      </div>

      {expandedSection === "products" && (
        <div className="pl-6">
          {[8, 9, 10].map(num => (
            <div
              key={num}
              className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
              onClick={() => setActive(num)}
            >
              {num === 8 ? <FaPlus size={20} color={active === num ? "#4A0000" : ""} /> : 
               num === 9 ? <FaChartLine size={20} color={active === num ? "#4A0000" : ""} /> : 
               <FaBoxOpen size={20} color={active === num ? "#4A0000" : ""} />}
              <span className={`pl-3 ${active === num ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
                {num === 8 ? "Add Products" : num === 9 ? "Market Insights" : "My Products"}
              </span>
            </div>
          ))}
        </div>
      )}

      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={() => { setActive(11); navigate("/inbox"); }}
      >
        <AiOutlineMessage size={20} color={active === 11 ? "#4A0000" : ""} />
        <span className={`pl-3 ${active === 11 ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          Quotes
        </span>
      </div>

      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={() => { setActive(12); navigate("/products"); }}
      >
        <AiOutlineShop size={20} color={active === 12 ? "#4A0000" : ""} />
        <span className={`pl-3 ${active === 12 ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          Products
        </span>
      </div>
      <div
        className="flex items-center mb-8 w-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-pink-200 hover:bg-opacity-70 p-2 rounded-md"
        onClick={handleLogout}
      >
        <AiOutlineLogout size={20} color={active === 13 ? "#4A0000" : ""} />
        <span className={`pl-3 ${active === 13 ? "text-[#4A0000]" : ""} ${isSidebarExpanded ? 'block' : 'hidden'} md:block`}>
          Logout
        </span>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

export default ProfileSideBar;