import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImgExample from "../../assets/seba_img.jpg";


const Product = ({ data }) => { 
  const imageUrl = typeof data.image_link === 'string' ? data.image_link : ImgExample;

  // Conversion rate from GHS to BWP
  const conversionRate = 0.968;
  const convertedPrice = (data.price * conversionRate).toFixed(2);

  return (
    <div className="bg-white rounded-lg shadow-sm w-full relative p-3 cursor-pointer border-2 border-gray-300 transform hover:scale-105 transition duration-500 ease-in-out">
      <div className="flex justify-end"></div>
      <Link to={`/product/${data.slug}`} state={{ product: data }}>
        <img
          src={imageUrl}
          alt="product/image"
          className="w-full object-contain select-none h-[130px] bg-gray-50"
        />
      </Link>

      <Link to={`/product/${data.slug}`} state={{ product: data }}>
        <h4 className="pb-3 font-medium">
          {data.title}
        </h4>
      </Link>

      <div className="py-2 flex items-center select-none justify-between">
        <div className="flex">
          <span className="font-medium">From&nbsp;&nbsp; </span>
          <h5 className="font-bold text-[16px] text-[#d55b45] font-Roboto">
            {convertedPrice}&nbsp;
          </h5>
          <span className="font-medium"> BWP</span>
        </div>
      </div>

      <div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

export default Product;