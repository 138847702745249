import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../../Styles/Style";
import { RxDashboard } from "react-icons/rx";
import Product from "../../Product";

const SellerProfileData = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [active, setActive] = useState(1);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(apiUrl + "/api/product/sellers/me/products/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-full">
        <div className="w-full items-center flex justify-between">
          <div className="flex items-center flex-wrap">
            <div
              className="flex items-center mr-5"
              onClick={() => setActive(1)}
            >
              <h5
                className={`font-[600] text-[20px] ${
                  active === 1 ? "text-[red]" : "text-[#333]"
                } cursor-pointer`}
              >
                Business Products
              </h5>
            </div>

          </div>

          <Link to="/dashboard">
            <button
              className={`${styles.button} !h-11 !rounded-[4px] text-white`}
            >
              <RxDashboard className="cursor-pointer mr-2" />
              Go Dashboard
            </button>
          </Link>
        </div>

        {active === 1 && (
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12 border-0 mt-12">
            {products.map((product) => (
              <Product data={product} key={product.id} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SellerProfileData;