import React from 'react';

const OrderProgressBar = () => {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex-1 flex items-center relative">
        <div className="text-red-500">Shipping</div>
        <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full mx-2">
          <svg className="w-4 h-4 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <div className="absolute top-1/2 left-full w-full h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
      </div>
      <div className="flex-1 flex items-center relative">
        <div className="text-gray-500">Delivery</div>
        <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full mx-2">
          <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <div className="absolute top-1/2 left-full w-full h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
      </div>
      <div className="flex-1 flex items-center relative">
        <div className="text-gray-500">Payment</div>
        <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full mx-2">
          <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <div className="absolute top-1/2 left-full w-full h-0.5 bg-gray-300 transform -translate-y-1/2"></div>
      </div>
      <div className="flex-1 flex items-center">
        <div className="text-gray-500">Confirm</div>
        <div className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full mx-2">
          <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default OrderProgressBar;
