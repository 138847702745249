import React, { useState, useRef } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupPhoto from "../../../assets/exporter.jpg";
import ToggleBar from "../Toggle";
import { storage, ref, uploadBytesResumable, getDownloadURL } from "../../../firebase";
import LoadingSpinner from "../LoadingSpinner";

const african_countries = [
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cameroon",
  "Central African Republic",
  "Chad",
  "Comoros",
  "Congo",
  "Democratic Republic of the Congo",
  "Côte d'Ivoire",
  "Djibouti",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Ethiopia",
  "Eswatini",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Mauritius",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Nigeria",
  "Rwanda",
  "São Tomé and Príncipe",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Sudan",
  "Swaziland", // Swaziland is now Eswatini
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
  "Zimbabwe"
];
const SignupNewSeller = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [businessLicence, setBusinessLicence] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fileInputRef = useRef(null);
  const licenceInputRef = useRef(null);
  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File uploaded:", file.name);
      setPassportPhoto(file);
      toast.success("Passport photo uploaded successfully!");
    }
  };

  const handleLicenceUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Licence uploaded:", file.name);
      setBusinessLicence(file);
      toast.success("Business license uploaded successfully!");
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleLicenceClick = () => {
    licenceInputRef.current.click();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("full_name", name);
    formData.append("password", password);
    formData.append("mobile_number", mobileNumber);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("country", country);
    formData.append("company_name", companyName);

    const uploadFiles = async () => {
      const urls = {};
      if (passportPhoto) {
        const passportRef = ref(storage, `sellers/${country}/${passportPhoto.name}`);
        await uploadBytesResumable(passportRef, passportPhoto);
        urls.passport_photo = await getDownloadURL(passportRef);
      }
      if (businessLicence) {
        const licenceRef = ref(storage, `sellers/${country}/${businessLicence.name}`);
        await uploadBytesResumable(licenceRef, businessLicence);
        urls.business_licence = await getDownloadURL(licenceRef);
      }
      return urls;
    };

    try {
      const urls = await uploadFiles();
      formData.append("passport_photo", urls.passport_photo || "");
      formData.append("business_licence", urls.business_licence || "");

      const response = await fetch(apiUrl + "/api/accounts/register/seller/", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        toast.success("Your account successfully created, Now login!");
        navigate("/profile");
      } else {
        const errorData = await response.json();
        setError(errorData.detail || "Something went wrong");
      }
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">
            Create an account
          </h2>
        </div>
        <div className="flex justify-center items-stretch h-full gap-4 md:flex-row flex-col">
          <div className="md:w-1/2 w-full md:flex hidden items-center justify-center">
            <img
              src={SignupPhoto}
              alt="African woman selling tomatoes"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-1/2 bg-white shadow sm:rounded-lg sm:px-10 flex flex-col justify-center">
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <LoadingSpinner />
              </div>
            ) : (
              <form className="space-y-6" onSubmit={handleFormSubmit}>
                <div>
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700 mt-4"
                  >
                    Business Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="companyName"
                      autoComplete="companyName"
                      required
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Names
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      autoComplete="fullName"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="number"
                      autoComplete="MobileNumber"
                      required
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="address"
                      autoComplete="address"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="city"
                      autoComplete="city"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country
                  </label>
                  <div className="mt-1">
                    <select
                      name="country"
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      <option value="" disabled>Select a country</option>
                      {african_countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type={visible ? "text" : "password"}
                      name="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    {visible ? (
                      <AiOutlineEye
                        className="absolute right-2 top-2 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute right-2 top-2 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(true)}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <p
                    className="text-gray-500 cursor-pointer font-bold"
                    onClick={handleClick}
                  >
                    Upload passport photo
                  </p>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                  {passportPhoto && (
                    <p className="text-red-700">{passportPhoto.name}</p>
                  )}
                </div>
                <div>
                  <p
                    className="text-gray-500 cursor-pointer font-bold"
                    onClick={handleLicenceClick}
                  >
                    Upload business license
                  </p>
                  <input
                    type="file"
                    ref={licenceInputRef}
                    className="hidden"
                    onChange={handleLicenceUpload}
                  />
                  {businessLicence && (
                    <p className="text-red-700">{businessLicence.name}</p>
                  )}
                </div>

                <div className="flex justify-center">
                  <ToggleBar className="w-1/2" />
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full h-[40px] mt-[30px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#4A0000] hover:bg-red-700"
                  >
                    Submit
                  </button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                  />
                </div>
                <div className="flex w-full">
                  <h4>Already have an account?</h4>
                  <Link to="/login-seller" className="text-[#4A0000] pl-2">
                    LogIn
                  </Link>
                </div>
              </form>
            )}
            {error && (
              <div className="text-center text-red-500 mt-4">{error}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupNewSeller;