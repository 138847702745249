import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/orderSucces.json";
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSuccess = () => {
    navigate('/profile');
  };


  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-lg w-full text-center">
        <Lottie options={defaultOptions} width={300} height={300} />
        <h5 className="text-[25px] text-gray-700 mb-4 font-semibold">
          Payment successful! <span role="img" aria-label="heart eyes">😍</span>
        </h5>
        <p className="text-gray-500 mb-8">
          Thank you for trusting us with your business.
        </p>
        <div className="flex justify-center space-x-4">
          <button 
            onClick={handleSuccess}
            className="bg-red-500 text-white font-bold py-2 px-6 rounded-md shadow hover:bg-red-600 transition duration-300"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
