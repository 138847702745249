import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Checkout = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [orderDetails, setOrderDetails] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [buyerDetails, setBuyerDetails] = useState(null);
  const [sellerDetails, setSellerDetails] = useState(null);
  const [formData, setFormData] = useState({
    logistic: 'Ethiopian',
    buyer_name: '',
    buyer_company: '',
    buyer_address_treet: '',
    buyer_phone: '',
    buyer_email: '',
    buyer_city: '',
  });

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId) {
        console.error('Order ID is undefined');
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/orders/detail/${orderId}/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setOrderDetails(data);
          setProductDetails(data.product_details);
          setBuyerDetails(data.buyer_details);
          setSellerDetails(data.seller_details);

          setFormData({
            logistic: 'Ethiopian',
            buyer_name: data.buyer_details?.full_name || '',
            buyer_company: data.buyer_details?.company_name || '',
            buyer_address_treet: data.buyer_details?.address || '',
            buyer_phone: data.buyer_details?.mobile_number || '',
            buyer_email: data.buyer_details?.email || '',
            buyer_city: data.buyer_details?.city || '',
          });
        } else {
          console.error('Failed to fetch order details');
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderId, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateAirFreightCost = () => {
    if (!orderDetails) {
      return 0;
    }
    const unitCost = formData.logistic === 'TheDuoGlobal' ? 2 : 3.8;
    return orderDetails.quantity * unitCost * 14.99;
  };

  const deliveryTime = formData.logistic === 'TheDuoGlobal' ? '7 days' : '5 days';
  const airFreightCost = calculateAirFreightCost();
  const totalAmount = (parseFloat(orderDetails?.amount) || 0) * 14.99 + airFreightCost;

  const handlePlaceOrder = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('No access token found. User is not logged in.');
      return;
    }
    const shippingData = {
      logistic: formData.logistic,
      shipping_order: orderDetails.id,
      buyer: buyerDetails.id,
      delivery_time: deliveryTime,
      cost: airFreightCost.toFixed(2), // Ensure 2 decimal places
      amount: totalAmount.toFixed(2), // Ensure 2 decimal places
    };

    console.log('Shipping Data:', shippingData);

    try {
      const response = await fetch(apiUrl + '/api/orders/shipping/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(shippingData),
      });

      if (response.ok) {
        navigate('/delivery', {
          state: {
            orderDetails,
            productDetails,
            buyerDetails,
            sellerDetails,
            shippingDetails: shippingData,
            deliveryTime,
            airFreightCost,
            totalAmount,
          },
        });
      } else {
        const errorText = await response.text();
        console.error('Failed to submit shipping details. Server response:', errorText);
      }
    } catch (error) {
      console.error('Error submitting shipping details:', error);
    }
  };

  if (!orderDetails || !productDetails || !buyerDetails || !sellerDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between">
        {/* Billing Details Form */}
        <div className="w-full md:w-1/2 p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Shipping Details</h2>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logisticCompany">Logistic Company</label>
              <select
                id="logisticCompany"
                name="logistic"
                value={formData.logistic}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md"
              >
                <option value="Ethiopian">Ethiopian</option>
                <option value="TheDuoGlobal">TheDuoGlobal</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buyer_name">First Name*</label>
              <input
                id="buyer_name"
                name="buyer_name"
                type="text"
                value={formData.buyer_name}
                onChange={handleChange}
                placeholder="First Name"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buyer_address_treet">Street Address*</label>
              <input
                id="buyer_address_treet"
                name="buyer_address_treet"
                type="text"
                value={formData.buyer_address_treet}
                onChange={handleChange}
                placeholder="Street Address"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buyer_city">Town/City*</label>
              <input
                id="buyer_city"
                name="buyer_city"
                type="text"
                value={formData.buyer_city}
                onChange={handleChange}
                placeholder="Town/City"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buyer_email">Email Address*</label>
              <input
                id="buyer_email"
                name="buyer_email"
                type="email"
                value={formData.buyer_email}
                onChange={handleChange}
                placeholder="Email Address"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>
          </form>
        </div>

        {/* Order Summary */}
        <div className="w-full md:w-1/2 p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <img src={productDetails.image_link} alt="Raw Shea Butter" className="mr-2 w-10 h-10" />
                <span>{productDetails?.title || 'Product Title'}</span>
              </div>
              {/* <span>{orderDetails?.id || 'Order ID'}</span> */}
            </div>
            <div className="flex justify-between items-center mb-5">
              <span className='font-bold'>Delivery Time:</span>
              <span>{deliveryTime}</span>
            </div>
            <hr className='h-1 bg-[#4A0000] md:mb-3'></hr>
            <div className="flex justify-between items-center mb-5">
              <span className='font-bold'>Conversion Rate:</span>
              <span>$1 = BWP 14.99</span>
            </div>
            <hr className='h-1 bg-[#4A0000] md:mb-3'></hr>
            <div className="flex justify-between items-center mb-5">
              <span className='font-bold'>Air Freight Cost:</span>
              <span>BWP {airFreightCost.toFixed(2)}</span>
            </div>
            <hr className='h-1 bg-[#4A0000] md:mb-3'></hr>
            <div className="flex justify-between items-center mb-5">
              <span className='font-bold'>Subtotal:</span>
              <span>BWP {(parseFloat(orderDetails?.amount) * 14.99 || 0).toFixed(2)}</span>
            </div>
            <hr className='h-1 bg-[#4A0000] md:mb-3'></hr>
            <div className="flex justify-between items-center mb-5">
              <span className='font-bold'>Total:</span>
              <span className='font-semibold'>BWP {totalAmount.toFixed(2)}</span>
            </div>
            <button 
              onClick={handlePlaceOrder}
              className="bg-[#4A0000] text-white font-bold w-full p-3 rounded-md hover:bg-[#4a0000c2]">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;