import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../../assets/orderSucces.json";
import { useNavigate } from 'react-router-dom';

const OrderSuccess = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleViewOrder = () => {
    navigate('/order/track');
  };

  const handleContinueShopping = () => {
    navigate('/products');
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-lg w-full text-center">
        <Lottie options={defaultOptions} width={300} height={300} />
        <h5 className="text-[25px] text-gray-700 mb-4 font-semibold">
          Your order is successful! <span role="img" aria-label="heart eyes">😍</span>
        </h5>
        <p className="text-gray-500 mb-8">
          Thank you for shopping with us. Your order has been placed successfully.
        </p>
        <div className="flex justify-center space-x-4">
          <button 
            onClick={handleViewOrder}
            className="bg-red-500 text-white font-bold py-2 px-6 rounded-md shadow hover:bg-red-600 transition duration-300"
          >
            View Order
          </button>
          <button 
            onClick={handleContinueShopping}
            className="bg-gray-200 text-gray-700 font-bold py-2 px-6 rounded-md shadow hover:bg-gray-300 transition duration-300"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
