import React, { useEffect, useState } from "react";
import avatar from "../../../../assets/avatar.jpg";
import styles from "../../../../Styles/Style";
import { Link, useNavigate } from "react-router-dom";

const SellerProfileSideBar = ({ isOwner }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [sellerInfo, setSellerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerInfo = async () => {
      try {
        const response = await fetch(apiUrl + "/api/accounts/sellers/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const data = await response.json();
        setSellerInfo(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching seller info:", error);
        setLoading(false);
      }
    };

    fetchSellerInfo();
  }, [apiUrl]);

  const handleLogout = async () => {
    try {
      const response = await fetch(apiUrl + "/api/accounts/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem("access_token");
        navigate("/login-seller");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!sellerInfo) {
    return <div>Error loading seller info</div>;
  }

  return (
    <>
      <div className="w-full py-5">
        <div className="flex items-center justify-center w-full">
          <img
            src={sellerInfo.passport_photo ? sellerInfo.passport_photo : avatar}
            alt="seller/image"
            title="Profile"
            className="800px:w-[150px] 800px:h-[150px] w-[120px] h-[120px] object-cover cursor-pointer border border-[#3957db] rounded-full"
          />
        </div>
        <h3 className="py-2 text-center font-[600] text-[20px]">
          {sellerInfo.full_name}
        </h3>
        <img
          src={sellerInfo.business_licence || ""}
          alt="licence"
          title="licence image"
          className="800px:w-[150px] 800px:h-[150px] w-[120px] h-[120px] object-cover cursor-pointer border"
        />
      </div>

      <div className="p-3 h-max w-max">
        <h3 className="font-[600]">Address</h3>
        <p className="text-[#000000a6]">{sellerInfo.address}</p>
      </div>

      <div className="p-2">
        <h3 className="font-[600]">Phone Number</h3>
        <p className="text-[#000000a6]">{sellerInfo.mobile_number}</p>
      </div>

      <div className="p-2">
        <h3 className="font-[600]">Total Products</h3>
        <p className="text-[#000000a6]">{sellerInfo.number_of_products || 0}</p>
      </div>

      <div className="p-2">
        <h3 className="font-[600]">Shop Ratings</h3>
        <p className="text-[#000000a6]">4.2</p>
      </div>

      <div className="p-2">
        <h3 className="font-[600]">Joined On</h3>
        <p className="text-[#000000a6]">{new Date(sellerInfo.date_joined).toLocaleDateString()}</p>
      </div>

      {isOwner && (
        <div className="py-3 px-4">
          <Link to="/dashboard-settings">
            <button
              className={`${styles.button} !h-11 !rounded-[4px] text-white !w-full`}>
              Edit Business
            </button>
          </Link>

          <button
            onClick={handleLogout}
            className={`${styles.button} !bg-red-600 !h-11 !rounded-[4px] text-white !w-full`}>
            Log Out
          </button>
        </div>
      )}
    </>
  );
};

export default SellerProfileSideBar;