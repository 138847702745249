import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import styles from "../../../../Styles/Style";
import { AiOutlineCamera } from "react-icons/ai";
import { motion } from "framer-motion";
import avatar from "../../../../assets/avatar.jpg";

const Settings = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const oldInfo = JSON.parse(localStorage.getItem("sellerInfo"));

  const [email, setEmail] = useState(oldInfo ? oldInfo.email : "");
  const [fullName, setFullName] = useState(oldInfo ? oldInfo.full_name : "");
  const [companyName, setCompanyName] = useState(oldInfo ? oldInfo.company_name : "");
  const [mobileNumber, setMobileNumber] = useState(oldInfo ? oldInfo.mobile_number : "");
  const [address, setAddress] = useState(oldInfo ? oldInfo.address : "");
  const [country, setCountry] = useState(oldInfo ? oldInfo.country : "");
  const [city, setCity] = useState(oldInfo ? oldInfo.city : "");
  const [passportPhoto, setPassportPhoto] = useState(oldInfo ? oldInfo.passport_photo : "");
  const [businessLicence, setBusinessLicence] = useState(oldInfo ? oldInfo.business_licence : null);
  const [photoUrl, setPhotoUrl] = useState();

  const shopLogo = oldInfo?.passport_photo;

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setPhotoUrl(url);
  };

  const handleBusinessLicenceChange = (e) => {
    const file = e.target.files[0];
    setBusinessLicence(file);
  };

  const updatedData = {
    email,
    full_name: fullName,
    company_name: companyName,
    mobile_number: mobileNumber,
    address,
    country,
    city,
    passport_photo: photoUrl || passportPhoto,
    business_licence: businessLicence,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("full_name", fullName);
    formData.append("company_name", companyName);
    formData.append("mobile_number", mobileNumber);
    formData.append("address", address);
    formData.append("country", country);
    formData.append("city", city);
    if (photoUrl) formData.append("passport_photo", photoUrl);
    if (businessLicence) formData.append("business_licence", businessLicence);

    try {
      const response = await fetch(apiUrl + "/api/accounts/seller/update/", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to update seller details");
      }

      const data = await response.json();
      toast.success("Your account information updated!");
      localStorage.setItem("sellerInfo", JSON.stringify(data));
    } catch (error) {
      console.error("Error updating seller details:", error);
      toast.error("Failed to update seller details");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}
      className="w-full h-[80vh] shadow overflow-y-scroll bg-white py-3 px-5"
      style={{ scrollbarWidth: "none" }}
    >
      <div className="flex justify-center w-full">
        <div className="relative h-[150px]">
          <img
            src={photoUrl ? photoUrl : shopLogo ? shopLogo : avatar}
            alt="profile/image"
            className="w-[150px] h-[150px] object-cover rounded-full border-[3px] border-[red]"
          />

          <div className="absolute w-[30px] h-[30px] bg-[#e3e9ee] bottom-[5px] rounded-full flex items-center justify-center right-[5px] cursor-pointer">
            <input
              type="file"
              id="profile/image"
              className="hidden"
              onChange={handleImageChange}
            />

            <label htmlFor="profile/image">
              <AiOutlineCamera className="cursor-pointer" />
            </label>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="w-full px-5">
        <form onSubmit={handleSubmit} aria-required>
          <div className="flex w-full flex-wrap pb-3">
            {/* email */}
            <div className="w-full 800px:w-[50%] pb-4">
              <label htmlFor="email" className="block pb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* full name */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="fullName" className="block pb-2">
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* company name */}
            <div className="w-full 800px:w-[50%] pb-4">
              <label htmlFor="companyName" className="block pb-2">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                required
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* mobile number */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="mobileNumber" className="block pb-2">
                Mobile Number
              </label>
              <input
                type="text"
                name="mobileNumber"
                id="mobileNumber"
                required
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* address */}
            <div className="w-full 800px:w-[50%] pb-4">
              <label htmlFor="address" className="block pb-2">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* country */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="country" className="block pb-2">
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* city */}
            <div className="w-full 800px:w-[50%] pb-4">
              <label htmlFor="city" className="block pb-2">
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* business licence */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="businessLicence" className="block pb-2">
                Business Licence
              </label>
              <input
                type="file"
                name="businessLicence"
                id="businessLicence"
                required
                value={businessLicence}
                onChange={handleBusinessLicenceChange}
                className={`${styles.input} py-2 !w-[95%] focus:border-[red]`}
              />
            </div>

            {/* button */}
            <div
              className={`${styles.button} hover:text-white mx-auto !rounded-[5px] w-[80%] 800px:w-[50%] transition !h-11 border bg-transparent border-[red] hover:bg-[red]`}
            >
              <button type="submit">Update Business</button>
            </div>
          </div>
          {/* toast message */}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
        </form>
      </div>
    </motion.div>
  );
};

export default Settings;