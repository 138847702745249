import React from "react";
import { useNavigate } from 'react-router-dom';

import processingGif from "../../assets/processing.gif";

const TrackOrder = () => {
  const navigate = useNavigate();

  const handleBackToShop = () => {
    navigate('/products');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-20 w-full mt-4 max-w-7xl">
        <div className="flex flex-col items-center justify-center mb-8">
          <img
            src={processingGif}
            alt="Processing"
            className="w-[150px] h-[150px] mb-4"
          />
          <h1 className="font-bold text-2xl text-gray-800 mb-2">
            Your order is being processed
          </h1>
          <p className="text-gray-600 mb-4">
            We are preparing your order for shipment. You can track its progress below.
          </p>
        </div>
        <div className="w-full px-5">
          {/* Header */}
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-bold">Order ID: 3354654654526</h1>
            <div className="flex space-x-2">
              <button className="px-4 py-2 bg-white text-black rounded border border-gray-400">Invoice</button>
              <button className="px-4 py-2 bg-purple-600 text-white rounded">Track order</button>
            </div>
          </div>

          {/* Progress Bar */}
          <div className="mb-8">
            <div className="text-sm mb-2">
              <span className="mr-4">Order date: July 16, 2024</span>
              <span className="font-bold text-green-500">Estimated delivery: July 30, 2024</span>
            </div>

            <div className="flex items-center space-x-2">
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div className="bg-green-500 w-4 h-4 rounded-full"></div>
                  <div className="ml-2 text-green-500">Order Confirmed</div>
                </div>
                <div className="text-xs text-gray-500">Wed, 16 July</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                  <div className="ml-2">Shipped</div>
                </div>
                <div className="text-xs text-gray-500">Thur, 17 July</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                  <div className="ml-2">Out For Delivery</div>
                </div>
                <div className="text-xs text-gray-500">Thur, 17 July</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                  <div className="ml-2">Delivered</div>
                </div>
                <div className="text-xs text-gray-500">Expected by, Sun 30th</div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <button 
              onClick={handleBackToShop}
              className="bg-red-500 text-white font-bold py-2 px-6 rounded-md shadow hover:bg-red-600 transition duration-300 mb-6"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
