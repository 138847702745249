import React, { useEffect, useState } from 'react';
import styles from "../../Styles/Style";

const MarketDashboard = () => {
  const [data, setData] = useState({
    quantityExportedData: [],
    amountExportedData: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://raw.githubusercontent.com/KitwanaSh/datasets/main/sheabutter_data.json");
      const jsonData = await response.json();
      const sheaButterData = jsonData["Shea Butter"];

      const filteredData = sheaButterData.filter(item => 
        ["NIGERIA", "GHANA", "UGANDA", "COTE D'VOIRE"].includes(item.country)
      );

      const quantityExportedData = filteredData.map(item => ({
        country: item.country,
        quantity: item.quantity_exported_tons,
      }));

      const amountExportedData = filteredData.map(item => ({
        country: item.country,
        amount: item.amount_exported_usd,
      }));

      setData({ quantityExportedData, amountExportedData });
    };

    fetchData();
  }, []);

  const formatNumber = (value) => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + 'B';
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + 'M';
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + 'K';
    } else {
      return value.toString();
    }
  };

  return (
    <div className={`${styles.section}`}>
      <div className="flex justify-between items-center mb-[20px]">
        <div className="flex items-center">
          <div className="w-5 h-7 bg-[#4A0000] mr-2.5 rounded-sm"></div>
          <h2 className="text-xl font-semibold text-[#4A0000]">Market Insights</h2>
        </div>
      </div>
      <div className={`${styles.heading}`}>
        <h1>Trending Now On <span className='text-[#4A0000]'>Shea Butter</span></h1>
      </div>

      <div className="container mx-auto p-6 flex justify-center">
        <div className="md:flex md:space-x-4">
          <div className="bg-black p-6 rounded-lg text-center mb-4 md:mb-0 flex flex-col items-center">
          <h2 className='text-white font-extrabold mb-5 text-lg'>Quantity Exported Annually (Tons)</h2>
            <div className="flex space-x-4 mb-4">
              {data.quantityExportedData.map((item, index) => (
                <div key={index} className="text-[#ffc8c8] text-lg font-medium">{item.country}</div>
              ))}
            </div>
            <div className="flex space-x-4">
              {data.quantityExportedData.map((item, index) => (
                <div key={index} className="text-white">{formatNumber(item.quantity)} Tons</div>
              ))}
            </div>
          </div>
          <div className="bg-black p-6 rounded-lg text-center flex flex-col items-center">
          <h2 className='text-white font-extrabold mb-5 text-lg'>Amount Exported Annually (USD)</h2>
            <div className="flex space-x-4 mb-4">
              {data.amountExportedData.map((item, index) => (
                <div key={index} className="text-[#ffc8c8] text-lg font-medium">{item.country}</div>
              ))}
            </div>
            <div className="flex space-x-4">
              {data.amountExportedData.map((item, index) => (
                <div key={index} className="text-white">{formatNumber(item.amount)} USD</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketDashboard;