import React from "react";
import SignupNewSeller from "../Components/Layout/Seller/SignupNewSeller";
import Header from "../Components/Layout/Header";

const SellerSignUpPage = () => {
  return (
    <>
    <Header />
      <SignupNewSeller />
    </>
  );
};

export default SellerSignUpPage;
