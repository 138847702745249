import React from "react";

import DashboardProfileMain from "./ProfileContent/DashboardProfileMain"
import Profile from "./ProfileContent/Profile";
import Order from "./ProfileContent/Order";
import Address from "./ProfileContent/Address";
import MyPayments from "./ProfileContent/MyPayments"
import TrackOrders from "./ProfileContent/TrackOrders";
import CreateEvent from "./Dashboard/DashboardContent/SuggestedCountry";
import DiscuntCodes from "./Dashboard/DashboardContent/DiscuntCodes";
import Settings from "./Dashboard/DashboardContent/Settings";
import CreateProduct from "./Dashboard/DashboardContent/CreateProduct";
import TrackOrder from "./TrackOrder";
import ProductCategory from "../Layout/ProfileContent/ProductCategory";
import AllOrders from "./Dashboard/DashboardContent/AllOrders";

const ProfileContent = ({ active, setViewProfile }) => {


  return (
    <div className="w-full h-full py-8">
      {active === 1 && <DashboardProfileMain />}
      {active === 2 && <Profile setViewProfile={setViewProfile}/>}
      {active === 3 && <Address />}
      {active === 4 && <MyPayments />}
      {active === 5 && <Order />}
      {active === 6 && <AllOrders />}
      {active === 7 && <TrackOrders />}
      {active === 8 && <CreateProduct />}
      {active === 9 && <CreateEvent />}
      {active === 10 && <ProductCategory />}
      {active === 11 && <DiscuntCodes />}
      {active === 12 && <Settings />}

      
    </div>
  );
};

export default ProfileContent;
