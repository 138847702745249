import React from 'react';
import Header from '../Components/Layout/Header';
import Footer from '../Components/Layout/Footer';
import DashboardSidebar from '../Components/Layout/Dashboard/DashboardSidebar';
import PaymentOptions from '../Components/Layout/PaymentOptions';
import ProfileSideBar from '../Components/Layout/ProfileSideBar';

const MakePaymentPage = () => {
  return (
    <>
        <Header />
        <div className="flex w-full">
        <div className="w-[80px] 800px:w-[335px]">
            <ProfileSideBar active={8} />
        </div>

        <div className="w-full mt-10 px-5 ">
            <PaymentOptions/>
        </div>
        </div>

    </>
    );
    };

export default MakePaymentPage;
