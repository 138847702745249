import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import styles from "../../../../Styles/Style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../../firebase";

const CreateProduct = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const [image, setImage] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fdaCertificate, setFdaCertificate] = useState(null);
  const african_currencies = {
    Algeria: "DZD",
    Angola: "AOA",
    Benin: "XOF ben",
    Botswana: "BWP",
    "Burkina Faso": "XOF  burkin",
    Burundi: "BIF",
    Cameroon: "XAF Cam",
    "Cape Verde": "CVE",
    "Central African Republic": "XAF CAR",
    Chad: "XAF Chad",
    Comoros: "KMF",
    "Congo (Brazzaville)": "XAF Brazza",
    "Congo (Kinshasa)": "CDF",
    "Côte d'Ivoire": "XOF Ivor",
    Djibouti: "DJF",
    Egypt: "EGP",
    "Equatorial Guinea": "XAF Equ",
    Eritrea: "ERN",
    "Eswatini (formerly Swaziland)": "SZL",
    Ethiopia: "ETB",
    Gabon: "XAF Gab",
    Gambia: "GMD",
    Ghana: "GHS",
    Guinea: "GNF",
    "Guinea-Bissau": "XOF Bissau",
    Kenya: "KES",
    Lesotho: "LSL",
    Liberia: "LRD",
    Libya: "LYD",
    Madagascar: "MGA",
    Malawi: "MWK",
    Mali: "XOF Mali",
    Mauritania: "MRO",
    Mauritius: "MUR",
    Morocco: "MAD",
    Mozambique: "MZN",
    Namibia: "NAD",
    Niger: "XOF Nig",
    Nigeria: "NGN",
    Rwanda: "RWF",
    "São Tomé and Principe": "STN",
    Senegal: "XOF Sen",
    Seychelles: "SCR",
    "Sierra Leone": "SLL",
    Somalia: "SOS",
    "South Africa": "ZAR",
    "South Sudan": "SSP",
    Sudan: "SDG",
    Tanzania: "TZS",
    Togo: "XOF Togo",
    Tunisia: "TND",
    Uganda: "UGX",
    "United States": "USD",
    Zambia: "ZMW",
    Zimbabwe: "ZWL",
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleFdaCertificateChange = (e) => {
    const file = e.target.files[0];
    setFdaCertificate(file);
  };

  const productCreateSuccessful = () => {
    toast.success("Product Successfully Created");
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrl = "";
    if (image) {
      const storageRef = ref(storage, `products/${productName}/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
        },
        (error) => {
          console.error("Upload error:", error);
          toast.error("Failed to upload image");
          setLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            imageUrl = downloadURL;
            submitForm(imageUrl);
          });
        }
      );
    } else {
      submitForm(imageUrl);
    }
  };

  const submitForm = async (imageUrl) => {
    const formData = new FormData();
    formData.append("title", productName);
    formData.append("description", productDescription);
    formData.append("quantity", quantity);
    formData.append("starting_price", startingPrice);
    formData.append("price", price);
    formData.append("currency", currency);
    formData.append("unit", unit);
    formData.append("is_available", isAvailable);
    formData.append("image_link", imageUrl);

    try {
      const response = await fetch(apiUrl + "/api/product/create/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
      });

      if (response.ok) {
        productCreateSuccessful();
        navigate("/dashboard-suggested-country")
      } else {
        const errorData = await response.json();
        console.error("Server error:", errorData);
        toast.error(
          `Failed to create product: ${errorData.detail || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        className="w-full h-[80vh] shadow overflow-y-scroll py-3 px-5"
        style={{ scrollbarWidth: "none" }}
      >
        <h5 className="text-[30px] font-Poppins text-center">Create Product</h5>
        <br />

        <form
          className={`800px:flex w-full flex-col flex-between flex-wrap ${
            loading ? "opacity-60" : ""
          }`}
          onSubmit={handleOnSubmit}
        >
          <div className="w-full 800px:w-[100%] flex items-center">
            <div className="mr-4">
            <label className="pb-2">
              Product Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={productName}
              required
              onChange={(e) => setProductName(e.target.value)}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 mt-2 focus:border-blue-500 sm:text-sm"
              placeholder="Enter your product name.."
            />
            </div>
            <div className="ml-4">
            <label className="pb-2">
              FDA Certificate <span className="text-red-500">*</span>
            </label>
              <label
                htmlFor="fdaCertificate"
                className={`${styles.button} !bg-gradient-to-r from-[#DB4444] to-[#4A0000] text-white mr-3 !rounded-[4px] !h-11 !px-4`}
              >
                Add
                <AiOutlinePlusCircle
                  size={20}
                  className="ml-1 cursor-pointer"
                  color="#ffffff"
                />
              </label>
              <input
                type="file"
                id="fdaCertificate"
                name="fdaCertificate"
                onChange={handleFdaCertificateChange}
                className="hidden"
              />
              {fdaCertificate && (
                <span className="ml-2 text-gray-600">{fdaCertificate.name}</span>
              )}
            </div>
          </div>

          <div className="flex justify-start items-center mt-5">
            <div className="w-full 800px:w-[14%] mr-4">
              <label className="pb-2">
                Price <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 mt-2 focus:border-blue-500 sm:text-sm"
                placeholder="Product Price"
              />
            </div>

            <div className="w-full 800px:w-[20%]">
              <label className="pb-2">
                Currency <span className="text-red-500">*</span>
              </label>
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 mt-2 focus:border-blue-500 sm:text-sm"
                required
              >
                <option value="" disabled>
                  Select Currency
                </option>
                {Object.entries(african_currencies).map(([country, code]) => (
                  <option key={code} value={code}>
                    {country} ({code})
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full 800px:w-[10%] 800px:ml-20">
              <label className="pb-2">
                MOQ <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 mt-2 focus:border-blue-500 sm:text-sm"
                placeholder="eg. 200"
              />
            </div>

            <div className="w-full 800px:w-[10%] ml-4">
              <label className="pb-2">
                Unit <span className="text-red-500">*</span>
              </label>
              <select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 mt-2 focus:border-blue-500 sm:text-sm"
                required
              >
                <option value="" disabled>
                  Select Unit
                </option>
                <option value="KG">Kilogram</option>
                <option value="LITRE">Litre</option>
                <option value="UNIT">Unit</option>
                <option value="PIECE">Piece</option>
                <option value="METRE">Metre</option>
              </select>
            </div>
          </div>

          <div className="w-full 800px:w-[100%] mt-5">
            <label className="pb-2">
              Description <span className="text-red-500">*</span>
            </label>
            <textarea
              value={productDescription}
              required
              onChange={(e) => setProductDescription(e.target.value)}
              placeholder="Enter your product description.."
              className="appearance-none mt-2 block w-full h-[100px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            ></textarea>
          </div>

          <div className="w-full 800px:w-[100%] mt-5">
            <label className="pb-2">
              Upload Image <span className="text-[#4A0000]">*</span>
            </label>
            <input
              type="file"
              id="uploadImage"
              name="uploadImage"
              onChange={handleImageChange}
              className="hidden"
              required
            />
            <div className="flex items-center flex-wrap">
              <label
                htmlFor="uploadImage"
                className={`${styles.button} !bg-gradient-to-r from-[#DB4444] to-[#4A0000] text-white mr-3 !rounded-[4px] !h-11`}
              >
                Upload
                <AiOutlinePlusCircle
                  size={30}
                  className="ml-1 cursor-pointer"
                  color="#ffffff"
                />
              </label>

              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt="product/image"
                  className="w-[20%] h-[120px] mt-2 mr-3 object-cover"
                />
              )}
            </div>
          </div>

          <div className="w-full mt-6 flex items-center justify-center">
            <input
              type="submit"
              value="Create Product"
              className={`${styles.button} !w-full font-semibold 800px:!w-[50%] !rounded-[4px] !h-11 hover:!text-white text-white transition-colors !bg-[#4A0000] hover:!bg-[#DB4444]`}
            />
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
        />
      </motion.div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <ClipLoader color="#ffffff" size={50} />
        </div>
      )}
    </>
  );
};

export default CreateProduct;