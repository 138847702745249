import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineArrowRight, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { motion } from "framer-motion";

const AllOrders = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await fetch(apiUrl + "/api/orders/list/", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      const data = await response.json();
      setOrders(data);
    };

    fetchOrders();
  }, [apiUrl]);

  const handleClickOpen = (orderId) => {
    setOrderToDelete(orderId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (orderToDelete) {
      try {
        const response = await fetch(`${apiUrl}/api/orders/delete/${orderToDelete}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        if (response.ok) {
          setOrders(orders.filter(order => order.id !== orderToDelete));
        } else {
          console.error('Failed to delete order');
        }
      } catch (error) {
        console.error('Error deleting order:', error);
      } finally {
        setOpen(false);
      }
    }
  };

  const columns = [
    {
      field: "productTitle",
      headerName: "Product Title",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 150,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sellerCompanyName",
      headerName: "Seller's Company Name",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sellerCity",
      headerName: "Seller's City",
      minWidth: 150,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sellerEmail",
      headerName: "Seller's Email",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={`/seller/order/${params.id}`}>
            <Button>
              <AiOutlineArrowRight size={20} className="cursor-pointer hover:text-[red] transition-colors" />
            </Button>
          </Link>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button onClick={() => handleClickOpen(params.id)}>
            <AiOutlineDelete size={20} className="cursor-pointer hover:text-[red] transition-colors" />
          </Button>
        );
      },
    },
  ];

  const rows = orders.map(order => ({
    id: order.id,
    productTitle: order.product_details.title,
    amount: order.amount,
    unit: order.product_details.unit,
    sellerCompanyName: order.seller_details.company_name,
    sellerCity: order.seller_details.city,
    sellerEmail: order.seller_details.email,
  }));

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}
      className="w-full">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this order?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default AllOrders;