import React from 'react';
import { PaystackButton } from 'react-paystack';


const PayButton = ({ amount, email }) => {
    const publicKey = process.env.REACT_APP_PAYSTACK;

    console.log('amount:', amount);
    console.log('email:', email);
    console.log('publicKey:', publicKey);

    const onSuccess = (reference) => {
        console.log(reference);
        alert("Payment was successful!");
    };

    const onClose = () => {
        alert("Payment was closed.");
    };

    return (
        <PaystackButton
            text="Confirm Payment"
            email={email}
            amount={amount}
            publicKey={publicKey}
            onSuccess={onSuccess}
            onClose={onClose}
            className="bg-[#4A0000] text-white font-bold py-2 px-4 rounded hover:bg-[#4a0000b4] mb-4"
        />
    );
};

export default PayButton;