import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import PayButton from './PayButton';

const Delivery = () => {
  const location = useLocation();
  const { orderDetails, productDetails, buyerDetails, sellerDetails, shippingDetails, deliveryTime, airFreightCost, totalAmount } = location.state || {};

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  if (!orderDetails || !productDetails || !buyerDetails || !sellerDetails || !shippingDetails || !deliveryTime || !airFreightCost || !totalAmount) {
    return <p>Loading...</p>;
  }

  const orderAmount = parseFloat(orderDetails.amount);
  const amount = orderAmount;
  const email = buyerDetails.email;

  const handleConfirmPayment = () => {
    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
      window.location.href = '/buyer-profile';
    }, 3000);
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-start space-y-4 md:space-y-0 md:space-x-4 mt-7">
      {/* Order Summary */}
      <div className="w-full md:w-1/2 p-4 border border-[#4A0000] rounded shadow-md shadow-[#4A0000] max-w-md">
        <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
        <div className="mb-4">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <img src={productDetails.image_link} alt={productDetails.title} className="mr-2 w-10 h-10" />
              <span className="text-lg">{productDetails.title}</span>
            </div>
          </div>
          <div className='mb-4'>
            <p className="pb-4"><strong>Logistic Company:</strong> {shippingDetails.logistic}</p>
            <p className="pb-4"><strong>Delivery Time:</strong> {deliveryTime}</p>
            <p className="pb-4"><strong>Air Freight Cost:</strong> BWP {airFreightCost.toFixed(2)}</p>
            <p className="pb-4"><strong>Total Amount:</strong> BWP {totalAmount.toFixed(2)}</p>
          </div>
        </div>
      </div>

      {/* Buyer Details */}
      <div className="w-full md:w-1/2 p-4 border border-[#4A0000] rounded shadow-md shadow-[#4A0000] max-w-md">
        <h2 className="text-2xl font-bold mb-4">Your Details</h2>
        <div className="mb-4">
          <p className="pb-4"><strong>Name:</strong> {buyerDetails.full_name}</p>
          <p className="pb-4"><strong>Address:</strong> {buyerDetails.address}</p>
          <p className="pb-4"><strong>Email:</strong> {buyerDetails.email}</p>
          <p className="pb-4"><strong>City:</strong> {buyerDetails.city}</p>
        </div>
        <div className="flex justify-center">
          <button 
            onClick={handleConfirmPayment}
            className="bg-[#4A0000] text-white font-bold py-2 px-4 rounded hover:bg-[#4a0000b4] mb-4">
            Confirm Payment
          </button>
        </div>
      </div>

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Success!</h2>
            <p className="text-lg">Your payment has been successfully submitted.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Delivery;