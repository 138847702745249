import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from "../../Styles/Style";
import Export from "../../assets/ExportReadiness.jpg";

const ExportReadinessChecker = () => {
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        telephoneNumber: '',
        email: '',
        socialMediaAccount: '',
        exportRegistration: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to a server
        console.log('Form submitted:', formData);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-screen-xl flex-1 flex justify-center items-center">
                <div className="w-1/2 mr-8">
                    <img src={Export} alt="Export Readiness" className="w-full h-auto rounded-lg shadow-md" />
                </div>
                <div className={`${styles.section} w-1/2`}>
                    <div className="text-center mb-8">
                        <h3 className="text-xl font-semibold">Your Details</h3>
                        <div className="border-t border-gray-300 my-2"></div>
                        <h2 className="text-2xl font-bold">Please enter your contact information</h2>
                    </div>
                    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-md">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="companyName" className="block text-gray-700 font-bold mb-2">Company Name:</label>
                            <input
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="telephoneNumber" className="block text-gray-700 font-bold mb-2">Telephone Number:</label>
                            <input
                                type="text"
                                id="telephoneNumber"
                                name="telephoneNumber"
                                value={formData.telephoneNumber}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email:</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="socialMediaAccount" className="block text-gray-700 font-bold mb-2">Social Media Account:</label>
                            <input
                                type="text"
                                id="socialMediaAccount"
                                name="socialMediaAccount"
                                value={formData.socialMediaAccount}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="exportRegistration" className="block text-gray-700 font-bold mb-2">Export Registration:</label>
                            <input
                                type="text"
                                id="exportRegistration"
                                name="exportRegistration"
                                value={formData.exportRegistration}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                            />
                        </div>
                        <div className="text-right">
                            <Link to={"/products"} className="inline-block hover:opacity-60 border-b border-white">
                                <span className="bg-red-500 hover:bg-red-600 transition duration-300 px-6 py-2 text-white font-bold rounded">
                                    Next
                                </span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ExportReadinessChecker;
