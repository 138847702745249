import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  HomePage,
  ProductPage,
  BestSellingPage,
  FaqPage,
  LoginPage,
  SignUpPage,
  TradePage,
  AboutPage,
  ProductListingPage,
  ExportReadinessTestPage,
  MarketInsightsPage,
  ProductDetailsPage,
  ProfilePage,
  MessagePage,
  SellerSignUpPage,
  BuyerSignUpPage,
  SellerLoginPage,
  SellerProfilePage,
  MakePaymentPage,
  PaymentOptionsPage,
  DashboardPage,
  AllCouponCodesPage,
  AllEventsPage,
  AllProductPage,
  AllOrderPage,
  SellerProfileSettings,
  SellerMessagePage,
  CreateProductPage,
  SuccessPage,
  MyProductsPage,
  SuggestedCountryPage,
  WithdrawMoneyPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  OrderDetailsPage,
  SellerOrderDetailsPage,
  TrackOrderPage,
  ForgetPasswordPage,
  BuyerProfilePage,
  SellerForgetPasswordPage,
  ContactPage,
  DeliveryPage,
} from "./Routes";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* basic user interface routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/ExportReadinessTest" element={<ExportReadinessTestPage />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/best-selling" element={<BestSellingPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/product/:name" element={<ProductDetailsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/inbox" element={<MessagePage />} />
        <Route path="/trade" element={<TradePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />

        {/* seller account routes */}
        <Route path="/signup-seller" element={<SellerSignUpPage />} />
        <Route path="/productlisting" element={<ProductListingPage />} />
        <Route path="/login-seller" element={<SellerLoginPage />} />
        <Route path="/shop/:id" element={<SellerProfilePage />} />
        <Route path="/market-insights" element={<MarketInsightsPage />} />
        <Route path="/payment" element={<MakePaymentPage />} />
        <Route path="/payment-options" element={<PaymentOptionsPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/my-products" element={<MyProductsPage />} />

         {/* buyer account routes */}
         <Route path="/signup-buyer" element={<BuyerSignUpPage />} />
         <Route path="/buyer-profile" element={<BuyerProfilePage />} />

        {/* dashboard routes */}
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard-cupons" element={<AllCouponCodesPage />} />
        <Route path="/dashboard-events" element={<AllEventsPage />} />
        <Route path="/dashboard-products" element={<AllProductPage />} />
        <Route path="/dashboard-orders" element={<AllOrderPage />} />
        <Route path="/dashboard-settings" element={<SellerProfileSettings />} />
        <Route path="/dashboard-messages" element={<SellerMessagePage />} />
        <Route
          path="/dashboard-create-product"
          element={<CreateProductPage />}
        />
        <Route path="/dashboard-suggested-country" element={<SuggestedCountryPage />} />
        <Route
          path="/dashboard-withdraw-money"
          element={<WithdrawMoneyPage />}
        />

        {/* Checkout routes */}
        <Route path="/checkout/:orderId" element={<CheckoutPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/order/success" element={<OrderSuccessPage />} />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/order/track" element={<TrackOrderPage />} />

        {/* order detail routes */}
        <Route path="/order/:id" element={<OrderDetailsPage />} />
        <Route path="/seller/order/:id" element={<SellerOrderDetailsPage />} />
        <Route path="/order/track/:id" element={<TrackOrderPage />} />

        {/* forget password */}
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route
          path="/seller/forget-password"
          element={<SellerForgetPasswordPage />}
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
