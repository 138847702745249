import React, { useState, useEffect } from "react";
import styles from "../../Styles/Style";
import Logo from "../../assets/logo.png";
import { BiMenuAltLeft, BiMenu } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";

import Navbar from "./Navbar";

const Header = ({ activeHeading, setSearchQuery }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [openMenu, setOpenMenu] = useState(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsUserLoggedIn(true);
      fetchUserProfile();
    }
  }, []);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await fetch(apiUrl + '/api/accounts/buyer/profile/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setUserProfilePhoto(data.passport_photo);
        setUserType(data.user_type);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    }
  };

  return (
    <>
      <div className={`w-full h-[80px] bg-white shadow-md sticky top-0 left-0 flex items-center justify-between px-2 z-50`}>
        <Link to={"/"}>
          <img src={Logo} alt="Logo" className="flex hover:opacity-75" width={250} height={100} />
        </Link>

        <div className="flex items-center">
          {/* Toggle button for mobile menu */}
          <div className="800px:hidden flex items-center">
            <button onClick={() => setOpenMenu(!openMenu)}>
              <BiMenu size={30} />
            </button>
          </div>

          {/* Desktop menu */}
          <div className={`hidden 800px:flex ${styles.normalFlex}`}>
            <Navbar active={activeHeading} isUserLoggedIn={isUserLoggedIn} />
          </div>

          {/* Mobile menu */}
          {openMenu && (
            <div className="absolute top-[80px] left-0 w-full bg-white flex flex-col items-start p-4 shadow-md z-10 800px:hidden">
              <Navbar active={activeHeading} vertical isUserLoggedIn={isUserLoggedIn} />
            </div>
          )}

          <div className={`${styles.noramlFlex}`}>
            <div className="relative cursor-pointer mr-[15px]">
              <Link to={userProfilePhoto ? (userType === 'Seller' ? "/profile" : "/buyer-profile") : "/login-seller"}>
                {userProfilePhoto ? (
                  <img
                    src={userProfilePhoto}
                    alt="avatar"
                    className="w-[33px] h-[33px] rounded-full object-cover"
                  />
                ) : (
                  !isUserLoggedIn && <CgProfile size={30} color="rgba(62, 62, 62, 0.83)" />
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;