import React from 'react';

const Features = () => {
  return (
    <section className="px-6 py-12 bg-white">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
        <div className="bg-gray-100 p-6 rounded-lg shadow">
          <div className="text-4xl font-bold">55</div>
          <div className="mt-2">Connecting 55 African Countries</div>
        </div>
        <div className="bg-red-100 p-6 rounded-lg shadow">
          <div className="text-4xl font-bold">130 B</div>
          <div className="mt-2">Connecting a population of 130 billion people</div>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow">
          <div className="text-4xl font-bold">193 B</div>
          <div className="mt-2">Increasing Africa's income by 193 billion dollars</div>
        </div>
      </div>

      <section className="px-6 py-12 bg-white">
      <div className="max-w-7xl mx-auto items-center">
      <h1 className="text-3xl font-bold mb-6 mt-6">Features</h1>
        <p className="text-lg leading-relaxed mb-6">
        1. Vast Network of Sellers: Connect with numerous sellers across the African continent.
        <br />
        2. Competitive Prices: Negotiate and find the best deals to suit your budget.
        <br />
        3. High-Quality Products: Access a wide array of top-quality goods.
        <br />
        4. Unlock AfCFTA Potential: Take advantage of the African Continental Free Trade Area.
        </p>
        </div>
        </section>
    </section>
  );
};

export default Features;
