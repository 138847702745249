import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const product = location.state?.product;
  const [uiQuantity, setUiQuantity] = useState('');
  const [amount, setAmount] = useState('');
  const [offerStatus, setOfferStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [isSeller, setIsSeller] = useState(false);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails.user_type === 'Seller') {
      setIsSeller(true);
    }
    console.log(userDetails)
  }, []);

  const handleQuantityChange = e => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number.isInteger(Number(value)))) {
      setUiQuantity(value);
      if (value !== '') {
        setAmount((Number(value) * convertedPrice).toString());
      }
    }
  };

  const handleAmountChange = e => {
    const value = e.target.value;
    setAmount(value);
    if (value !== '') {
      setUiQuantity(Math.floor(Number(value) / convertedPrice).toString());
    }
  };

  useEffect(() => {
    let timer;
    if (showPopup) {
      timer = setTimeout(() => {
        setShowPopup(false);
        setOfferStatus('');
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showPopup]);

  const handleOfferSubmission = async (action) => {
    if (isSeller) {
      setOfferStatus('Sellers cannot make offers');
      setShowPopup(true);
      return;
    }

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      setOfferStatus('You need to log in to make an offer');
      setShowPopup(true);
      navigate('/sign-up');
      return;
    }

    const quantity = uiQuantity === '' ? 1 : Number(uiQuantity);

    if (quantity < 25) {
      setOfferStatus('The amount is not enough! Please increase.');
      setShowPopup(true);
    } else {
      try {
        const payload = {
          amount: parseFloat(amount),
          quantity,
        };

        console.log('Sending payload:', payload);

        const response = await fetch(
          `${apiUrl}/api/orders/create/${product.id}/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        console.log('Response data:', data);

        if (response.ok) {
          setOfferStatus('The offer has been successfully accepted!');
          setShowPopup(true);
          localStorage.setItem('orderDetails', JSON.stringify(data));
          localStorage.setItem('productDetails', JSON.stringify(data.product_details));
          localStorage.setItem('buyerDetails', JSON.stringify(data.buyer_details));
          localStorage.setItem('sellerDetails', JSON.stringify(data.seller_details));
          setTimeout(() => navigate(`/checkout/${data.id}`), 2000); // Navigate to checkout with order ID
        } else {
          setOfferStatus('The amount is not enough! Please increase.');
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Error submitting offer:', error);
        setOfferStatus('Failed to send offer');
        setShowPopup(true);
      }
    }
  };

  if (!product) return <p>Loading...</p>;

  // Conversion rate from GHS to BWP
  const conversionRate = 0.968;
  const convertedPrice = (product.price * conversionRate).toFixed(2);

  return (
    <div className="p-8 relative">
      <div className="flex flex-col md:flex-row md:space-x-8">
        <div className="w-full md:w-1/3 order-2 md:order-1">
          <img src={product.image_link} alt="Product" className="w-full h-auto" />
        </div>
        <div className="w-full md:w-2/3 order-1 md:order-2 mb-8 md:mb-0">
          <h2 className="text-2xl font-bold mb-2">{product.title}</h2>
          <p className="text-lg mb-4">Price: BWP {convertedPrice} / {product.unit}</p>
          <p className="mb-4 text-[#4A0000]">MOQ for this product is: {product.quantity} <span>{product.unit}</span></p>
          <p className="mb-4">{product.description}</p>
          <div className="flex items-center mb-4">
            <input
              type="text"
              placeholder="Price"
              className="border p-2 mr-2"
              value={amount}
              onChange={handleAmountChange}
            />
            <div className="flex items-center w-full">
              <input
                type="number"
                min="1"
                value={uiQuantity}
                onChange={handleQuantityChange}
                className="text-center w-20 border p-2"
                placeholder='Quantity'
              /> <span className='ml-3'>  /  </span>
              <h2 className="ml-2">{product.unit}</h2>
            </div>
          </div>
          <div className="flex flex-row ">
            <button
              className={`bg-[#4A0000] text-white py-2 px-6 rounded ${isSeller ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleOfferSubmission('negotiate')}
              disabled={isSeller}
            >
              Buy
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#4A0000] py-12 px-4 rounded-lg shadow-lg">
            <p className="text-white text-bolder text-center">{offerStatus}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;