import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import Styles from "../../../../Styles/Style";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { motion } from "framer-motion";

const MainDashboard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(apiUrl + "/api/product/sellers/me/products/", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      const data = await response.json();
      setProducts(data);
    };

    const fetchOrders = async () => {
      const response = await fetch(apiUrl + "/api/orders/list/", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      const data = await response.json();
      setOrders(data);
    };

    fetchProducts();
    fetchOrders();
  }, []);

  const columns = [
    { field: "id", headerName: "Order Id", minWidth: 150, flex: 0.8 },
    { field: "status", headerName: "Status", minWidth: 150, flex: 0.8 },
    { field: "item_qty", headerName: "Items Qty", type: "number", minWidth: 150, flex: 0.8 },
    { field: "total", headerName: "Total", type: "number", minWidth: 150, flex: 0.5 },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/seller/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} className="cursor-pointer hover:text-[red] transition-colors" />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const orderRows = orders.map(order => ({
    id: order.id,
    status: order.status,
    item_qty: order.amount,
    total: order.amount,
  }));

  return (
    <div className="w-full">
      <h1 className="text-[22px] font-Poppins pb-2">Overview</h1>
      <div className="800px:flex items-center justify-between block">
        

        <motion.div
          initial={{ opacity: 0, y: -150 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -150 }}
          transition={{ duration: 0.5 }}
          className="w-full 800px:w-[32%] bg-white hover:shadow-lg transition mb-4 px-3 py-3 min-h-[20vh] cursor-pointer rounded">
          <div className="flex items-center">
            <MdBorderClear size={25} className="mr-2" color="#00000085" />
            <h3 className={`${Styles.productTitle} !text-[18px] !font-[600] !text-[#00000085] leading-5`}>All Orders</h3>
          </div>
          <h3 className="text-[22px] font-[500] pl-[25px] pt-2">{orders.length}</h3>
          <Link to="/dashboard-orders">
            <h5 className="text-[#077f9c] pl-2 pt-4 hover:underline">view orders</h5>
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 150 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 150 }}
          transition={{ duration: 0.5 }}
          className="w-full 800px:w-[32%] bg-white hover:shadow-lg transition mb-4 px-3 py-3 min-h-[20vh] cursor-pointer rounded">
          <div className="flex items-center">
            <AiOutlineMoneyCollect size={30} className="mr-2" color="#00000085" />
            <h5 className={`${Styles.productTitle} !text-[18px] !font-[600] !text-[#00000085] leading-5`}>All Product</h5>
          </div>
          <h3 className="text-[22px] font-[500] pl-[25px] pt-2">{products.length}</h3>
          <Link to="/dashboard-products">
            <h5 className="text-[#077f9c] pl-2 pt-4 hover:underline">view products</h5>
          </Link>
        </motion.div>
      </div>

      <div className="w-full px-3 py-4">
        <h2 className="font-[600] text-[22px]">Latest Order</h2>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 200 }}
          transition={{ duration: 0.5 }}
          className="w-full bg-white mt-3">
          <DataGrid
            rows={orderRows.slice(0, 3)}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </motion.div>
      </div>
    </div>
  );
};

export default MainDashboard;