import React, { useState, useEffect } from "react";
import styles from "../../Styles/Style";
import Logo from "../../assets/logo.png";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { categoriesData, productData } from "../../Static/data";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import Cart from "../../Components/Cart";
import Wishlist from "../../Components/Wishlist";
import ResponsiveHeader from "./ResponsiveHeader/ResponsiveHeader";
import avatar from "../../assets/avatar.jpg";
import { useSelector } from "react-redux";

const ShopHeader = ({ activeHeading, setSearchQuery }) => {
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [click, setClick] = useState(false);

  // local storage
  const photoUrl = JSON.parse(localStorage.getItem("PhotoUrl"));
  const isUser = JSON.parse(localStorage.getItem("email"));

  // use selector
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);

  // scroll menu bar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 70) {
        setActive(true);
      } else {
        setActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // localStorage user data
  const isSeller = localStorage.getItem("isSeller");

  return (
    <>
      {/* responsive header */}
      <ResponsiveHeader
        active={active}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        setOpenCart={setOpenCart}
        setOpenWishlist={setOpenWishlist}
        searchData={searchData}
        activeHeading={activeHeading}
        cart={cart}
        click={click}
      />

      {/* header bottom */}
      <div
        className={`header-container ${active ? "fixed top-0 left-0 items-center shadow-sm z-10" : ""} 800px:flex hidden w-full bg-white items-center justify-between transition-all duration-300 p-4 border-b border-gray-300`}
        style={{ height: '80px' }} // Adjust the height here
      >
        <div
          className={`${styles.section} ${styles.noramlFlex} relative justify-between w-full`}
        >
          {/* categories bar */}
          <Link to={"/"}>
            <img src={Logo} alt="Logo" className="flex hover:opacity-75" width={100} height={20} /> {/* Adjust the width and height here */}
          </Link>

          {/* navbar section */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div>

          {/* Search and button */}
          <div className="hidden 800px:h-[50px] 800px:pt-12 800px:pb-12 800px:flex items-center">
            <div className={`relative w-full`}>
              <input
                type="text"
                className="w-full py-2 rounded border border-gray-300 focus:outline-none pl-2"
                placeholder="Search product..."
                onChange={handleInputChange}
              />
              <AiOutlineSearch
                size={30}
                color="#333"
                className="absolute top-1.5 right-2 cursor-pointer"
              />
            </div>
          </div>

           {/*End search and button */}

          <div className="flex">
            {/* <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart size={30} color="rgba(62, 62, 62, 0.83)" />
                <span className="absolute top-0 right-0 rounded-full w-4 h-4 top right m-0 p-0 font-mono text-white leading-tight text-[12px] bg-[#e64a07] text-center">
                  {cart ? cart.length : "0"}
                </span>
              </div>
            </div> */}

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                <Link to={isUser ? "/profile" : "/login-seller"}>
                  {isUser ? (
                    <img
                      src={photoUrl ? photoUrl : avatar}
                      alt="avatar"
                      className="w-[33px] h-[33px] rounded-full object-cover"
                    />
                  ) : (
                    <CgProfile size={30} color="rgba(62, 62, 62, 0.83)" />
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {openCart && <Cart setOpenCart={setOpenCart} openCart={openCart} />}
        {openWishlist && <Wishlist setOpenWishlist={setOpenWishlist} />}

      </div>
    </>
  );
};

export default ShopHeader;
