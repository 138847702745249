import React, { useState } from 'react';
import { Link} from 'react-router-dom';

const PaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState('Credit/Debit Cards');

  return (
    <div className="flex flex-col items-center w-full h-[80vh] shadow bg-white py-3 px-5">
      <h1 className="text-2xl font-bold mb-8">Make payment</h1>

      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Payment Methods</h2>

        <div className="space-y-4">
          <label className="flex items-center">
            <input
              type="radio"
              value="Credit/Debit Cards"
              checked={selectedOption === 'Credit/Debit Cards'}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="form-radio text-indigo-600"
            />
            <span className="ml-2 text-gray-700">Credit/Debit Cards</span>
            <span className="ml-auto text-gray-500">Pay with your Credit / Debit Card</span>
          </label>

          {selectedOption === 'Credit/Debit Cards' && (
            <div className="space-y-4 mt-4">
              <input
                type="text"
                placeholder="Card number"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <div className="flex space-x-4">
                <input
                  type="text"
                  placeholder="MM / YY"
                  className="w-1/2 p-2 border border-gray-300 rounded-lg"
                />
                <input
                  type="text"
                  placeholder="CVV"
                  className="w-1/2 p-2 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
          )}

          <label className="flex items-center">
            <input
              type="radio"
              value="Direct Bank Transfer"
              checked={selectedOption === 'Direct Bank Transfer'}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="form-radio text-indigo-600"
            />
            <span className="ml-2 text-gray-700">Direct Bank Transfer</span>
            <span className="ml-auto text-gray-500">Make payment directly through bank account.</span>
          </label>

          <label className="flex items-center">
            <input
              type="radio"
              value="Other Payment Methods"
              checked={selectedOption === 'Other Payment Methods'}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="form-radio text-indigo-600"
            />
            <span className="ml-2 text-gray-700">Other Payment Methods</span>
            <span className="ml-auto text-gray-500">Make payment through Gpay, Paypal, Paytm etc</span>
          </label>
        </div>
      </div>

      <div className="flex mt-8 space-x-4">
      <Link to={"/payment"} className="inline-block hover:opacity-60 mr-auto 800px:mr-0">
        <button className="px-6 py-2 border border-red-600 text-red-600 rounded-lg">Back</button>
      </Link>
      
        <Link to={"/success"} className="inline-block hover:opacity-60 mr-auto 800px:mr-0">
        <button className="px-6 py-2 bg-red-600 text-white rounded-lg">Pay GH₵100</button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentOptions;
