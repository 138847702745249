import React from 'react';

const SearchResults = ({ results }) => {
  if (!results) {
    return null; // Optional: Handle loading state or return an empty state
  }

  return (
    <div className="p-4 bg-gray-100">
      <h2 className="text-xl font-bold mb-4">Search Results</h2>
      <div className="flex flex-wrap gap-4">
        {results.map((result, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md shadow-md w-1/3 flex flex-col items-center"
          >
            <div className="text-lg font-bold">{result.name}</div>
            <div className="text-gray-700">Price: {result.price}</div>
            <div className="text-gray-700">Description: {result.description}</div>
            {/* Add more fields as needed */}
            <button className="mt-4 bg-red-500 text-white py-2 px-4 rounded-md">
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
