import React, { useState } from "react";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { FaChartBar, FaPlus, FaHistory, FaMoneyBill, FaInbox } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const DashboardSidebar = ({ active, setActive }) => {
  const [manageAccountExpanded, setManageAccountExpanded] = useState(false);
  const [ordersExpanded, setOrdersExpanded] = useState(false);
  const [productsExpanded, setProductsExpanded] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const navigate = useNavigate();

  const toggleManageAccount = () => {
    setManageAccountExpanded(!manageAccountExpanded);
  };

  const toggleOrders = () => {
    setOrdersExpanded(!ordersExpanded);
  };

  const toggleProducts = () => {
    setProductsExpanded(!productsExpanded);
  };

  return (
    <div className={`p-4 z-30 pt-8 bg-white shadow-sm w-full h-full transition-all duration-300 ${isSidebarExpanded ? 'w-1/2' : 'w-16'}`}>
      <div className="flex justify-end mb-4">
        <button onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}>
          {isSidebarExpanded ? <FaInbox size={24} /> : <FaInbox size={24} />}
        </button>
      </div>

      <div
        className="flex items-center w-full p-4 cursor-pointer"
        title="Manage Account"
        onClick={toggleManageAccount}
      >
        <FiShoppingBag
          size={25}
          color={manageAccountExpanded ? "#dc2626" : "#555"}
        />
        <span
          className={`pl-3 ${
            manageAccountExpanded ? "text-[#dc2626]" : "text-[#555]"
          } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
        >
          Manage Account
        </span>
      </div>

      {manageAccountExpanded && (
        <div className="pl-6">
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(1)}
          >
            <FaChartBar size={20} color={active === 1 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 1 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Main Dashboard
            </h5>
          </div>
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(2)}
          >
            <FaHistory size={20} color={active === 2 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 2 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Orders
            </h5>
          </div>
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(3)}
          >
            <FiPackage size={20} color={active === 3 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 3 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Products
            </h5>
          </div>
        </div>
      )}

      <div
        className="flex items-center w-full p-4 cursor-pointer"
        title="All Orders"
        onClick={toggleOrders}
      >
        <FiShoppingBag size={25} color={ordersExpanded ? "#dc2626" : "#555"} />
        <span
          className={`pl-3 ${
            ordersExpanded ? "text-[#dc2626]" : "text-[#555]"
          } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
        >
          All Orders
        </span>
      </div>

      {ordersExpanded && (
        <div className="pl-6">
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(4)}
          >
            <FaPlus size={20} color={active === 4 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 4 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Create Product
            </h5>
          </div>
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(5)}
          >
            <FaHistory size={20} color={active === 5 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 5 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Order History
            </h5>
          </div>
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(6)}
          >
            <FaChartBar size={20} color={active === 6 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 6 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Product Insights
            </h5>
          </div>
        </div>
      )}

      <div
        className="flex items-center w-full p-4 cursor-pointer"
        title="All Products"
        onClick={toggleProducts}
      >
        <FiPackage size={25} color={productsExpanded ? "#dc2626" : "#555"} />
        <span
          className={`pl-3 ${
            productsExpanded ? "text-[#dc2626]" : "text-[#555]"
          } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
        >
          My Products
        </span>
      </div>

      {productsExpanded && (
        <div className="pl-6">
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(7)}
          >
            <FaMoneyBill size={20} color={active === 7 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 7 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Withdraw
            </h5>
          </div>
          <div
            className="flex items-center w-full p-4 cursor-pointer"
            onClick={() => setActive(8)}
          >
            <BiMessageSquareDetail size={20} color={active === 8 ? "#dc2626" : "#555"} />
            <h5
              className={`pl-3 ${
                active === 8 ? "text-[#dc2626]" : "text-[#555]"
              } ${isSidebarExpanded ? 'block' : 'hidden'} text-[14px] font-[400]`}
            >
              Inbox
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardSidebar;