import React from "react";
import Header from "../Components/Layout/Header"
import SignupNewBuyer from "../Components/Layout/Buyer/SignUpNewBuyer";

const SignUpPage = () => {
  return (
    <div>
      <Header />
      <SignupNewBuyer/>
    </div>
  );
};

export default SignUpPage;
