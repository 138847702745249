import React, { useState, useEffect } from 'react';
import Footer from "../Components/Layout/Footer";
import Trade from "../Components/Layout/Trade";
import IndemandProducts from "../Components/Layout/IndemandProducts"
import AllProducts from '../Components/Layout/AllProducts';
import Categories from "../Components/Layout/Categories";
import { useSearchParams } from "react-router-dom";
import Product from "../Components/Layout/Product";
import SearchResults from "../Components/Layout/SearchResults";
import ShopHeader from '../Components/Layout/ShopHeader';

const TradePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl + "/api/product/all/");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredData = categoryData
    ? data.filter((product) => product.category === categoryData)
    : data;
  

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      // Replace with your API endpoint
      const apiUrl = `https://api.example.com/products?q=${encodeURIComponent(searchQuery)}`;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setSearchResults(data))
        .catch(error => console.error('Error fetching data:', error));
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  return (
    <>
      <ShopHeader setSearchQuery={setSearchQuery}/>
      <main className='flex-grow'>
        <SearchResults searchResults={searchResults} />
      </main>
      <Trade />

      <AllProducts />

      <Categories />

      <Footer />
    </>
  );
};

export default TradePage;
