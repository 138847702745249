import React from "react";
import SignupNewBuyer from "../Components/Layout/Buyer/SignUpNewBuyer";
import Header from "../Components/Layout/Header";

const BuyerSignUpPage = () => {
  return (
    <>
      <Header />
      <SignupNewBuyer />
    </>
  );
};

export default BuyerSignUpPage;
