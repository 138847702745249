import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables, Legend, Tooltip, LinearScale, CategoryScale } from "chart.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

Chart.register(...registerables, Legend, Tooltip, LinearScale, CategoryScale);

const SuggestedCountry = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [viewMode, setViewMode] = useState("table");
  const [selectedChart, setSelectedChart] = useState("Quantity Imported (Tons)");
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://raw.githubusercontent.com/KitwanaSh/datasets/main/sheabutter_data.json");
      const jsonData = await response.json();
      setFilteredProducts(jsonData["Shea Butter"]);
    };

    fetchData();
  }, []);

  const filteredImportData = filteredProducts.filter(product => ["KENYA", "BOTSWANA", "SOUTH AFRICA"].includes(product.country) && product.quantity_imported_tons !== null);
  const filteredExportData = filteredProducts.filter(product => product.amount_exported_usd !== null && product.quantity_exported_tons !== null);
  const filteredPriceData = filteredProducts.filter(product => product.price_usd !== null);

  const importChartData = {
    labels: filteredImportData.map(product => product.country),
    datasets: [
      {
        label: "Quantity Imported (Tons)",
        data: filteredImportData.map(product => product.quantity_imported_tons),
        backgroundColor: "rgba(235, 54, 54, 0.6)",
      },
    ],
  };

  const exportChartData = {
    labels: filteredExportData.map(product => product.country),
    datasets: [
      {
        label: "Amount Exported (USD)",
        data: filteredExportData.map(product => product.amount_exported_usd),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(199, 199, 199, 0.6)",
        ],
      },
    ],
  };

  const priceHistogramData = {
    labels: filteredPriceData.map(product => product.country),
    datasets: [
      {
        label: "Price Frequency",
        data: filteredPriceData.map(product => product.price_usd),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const renderChart = () => {
    switch (selectedChart) {
      case "Quantity Imported (Tons)":
        return (
          <div className="w-1/2 mx-auto">
            <h3 className="text-center text-xl text-[#4A0000] font-bold mb-6 mt-6">
              Quantity Imported (Tons) by Country
            </h3>
            <Bar
              ref={chartRef}
              data={importChartData}
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Quantity Imported (Tons)',
                    },
                  },
                },
              }}
            />
          </div>
        );
      case "Amount Exported (USD)":
        return (
          <div className="w-1/2 mx-auto">
            <h3 className="text-center text-xl text-[#4A0000] font-bold mb-6 mt-6">
              Amount Exported (USD) by Country
            </h3>
            <Bar
              ref={chartRef}
              data={exportChartData}
              options={{
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: 'Amount Exported (USD)',
                    },
                  },
                },
              }}
            />
          </div>
        );
      case "Price (USD) Frequency":
        return (
          <div className="w-1/2 mx-auto">
            <h3 className="text-center text-xl text-[#4A0000] font-bold mb-6 mt-6">
              Price (USD) Frequency
            </h3>
            <Bar
              ref={chartRef}
              data={priceHistogramData}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Price (USD)',
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Frequency',
                    },
                  },
                },
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}
      className="w-full h-[80vh] shadow overflow-y-scroll py-3 px-5"
      style={{ scrollbarWidth: "none" }}
    >
      <div className="flex items-center">
        <div>
          <label
            htmlFor="viewModeSelect"
            className="block text-sm font-medium text-gray-700"
          >
            View Mode
          </label>
          <select
            id="viewModeSelect"
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
            className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            style={{ width: "auto" }}
          >
            <option value="table">Table</option>
            <option value="chart">Chart</option>
          </select>
        </div>
        {viewMode === "chart" && (
          <div className="ml-4">
            <label
              htmlFor="chartSelect"
              className="block text-sm font-medium text-gray-700"
            >
              Select Chart
            </label>
            <select
              id="chartSelect"
              value={selectedChart}
              onChange={(e) => setSelectedChart(e.target.value)}
              className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              style={{ width: "auto" }}
            >
              <option value="Quantity Imported (Tons)">Quantity Imported (Tons)</option>
              <option value="Amount Exported (USD)">Amount Exported (USD) by Country</option>
              <option value="Price (USD) Frequency">Price (USD) Frequency</option>
            </select>
          </div>
        )}
      </div>

      {filteredProducts.length > 0 ? (
        <>
          <h1 className="text-3xl font-bold text-[#4A0000] text-center mb-6">
            Insights About Shea Butter
          </h1>
          {viewMode === "table" ? (
            <table className="w-full mb-6">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Country</th>
                  <th className="px-4 py-2 text-left">Price (USD)</th>
                  <th className="px-4 py-2 text-left">Amount Imported (USD)</th>
                  <th className="px-4 py-2 text-left">Quantity Imported (Tons)</th>
                  <th className="px-4 py-2 text-left">Amount Exported (USD)</th>
                  <th className="px-4 py-2 text-left">Quantity Exported (Tons)</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{product.country}</td>
                    <td className="px-4 py-2">{product.price_usd}</td>
                    <td className="px-4 py-2">{product.amount_imported_usd}</td>
                    <td className="px-4 py-2">{product.quantity_imported_tons}</td>
                    <td className="px-4 py-2">{product.amount_exported_usd}</td>
                    <td className="px-4 py-2">{product.quantity_exported_tons}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            renderChart()
          )}
        </>
      ) : (
        <h1 className="text-3xl font-bold text-center">
          No insights for this product
        </h1>
      )}

      <div className="flex justify-between mt-8">
        <Link to="/profile" className="hover:opacity-60">
          <span className="bg-[#4A0000] hover:bg-red-500 hover:opacity-40 transition-opacity duration-300 px-6 py-2 text-white font-bold rounded">
            Back
          </span>
        </Link>

        <Link to="/market-insights" className="hover:opacity-60">
          <span className="bg-[#4A0000] hover:bg-red-500 hover:opacity-40 transition-opacity duration-300 px-6 py-2 text-white font-bold rounded">
            Next
          </span>
        </Link>
      </div>
    </motion.div>
  );
};

export default SuggestedCountry;