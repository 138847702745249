import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Sidebar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showForm, setShowForm] = useState(false);
  const [formEnquiry, setFormEnquiry] = useState('');
  const [formQuantity, setFormQuantity] = useState('');
  const [formFullName, setFormFullName] = useState('');
  const [formDeliveryTime, setFormDeliveryTime] = useState('');
  const [formDeliveryAddress, setFormDeliveryAddress] = useState('');
  const [formDeliveryCity, setFormDeliveryCity] = useState('');
  const [formPhoneNumber, setFormPhoneNumber] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const toggleFormVisibility = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const quoteData = {
      product: formEnquiry,
      quantity: formQuantity,
      full_name: formFullName,
      delivery_time: formDeliveryTime,
      delivery_address: formDeliveryAddress,
      delivery_city: formDeliveryCity,
      phone_number: formPhoneNumber,
    };

    fetch(apiUrl + '/api/quote/create/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      },
      body: JSON.stringify(quoteData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        toast.success('Quote successfully submitted!');
        setShowForm(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Failed to submit quote.');
      });
  };

  const categories = ['All', 'Flour', 'Grains', 'Oils', 'Beauty&Cosmetics'];

  return (
    <aside className="w-full sm:w-1/1.4 lg:w-1/1.3 p-4 bg-white shadow-md">
      <h3 className="text-[18px] font-bold mb-4">Product Category</h3>
      <ul>
        {categories.map((category) => (
          <li key={category} className="mb-2">
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="category"
                value={category}
                checked={selectedCategory === category}
                onChange={() => setSelectedCategory(category)}
                className="mr-2"
              />
              <span
                className="text-[#555] hover:text-white hover:bg-[#4A0000] px-2 py-1 rounded"
              >
                {category}
              </span>
            </label>
          </li>
        ))}
      </ul>

      <button
        className={`bg-[#4A0000] text-white rounded hover:bg-[#4A0000] mb-2 px-6 mt-4 w-full`}
        onClick={toggleFormVisibility}
      >
        Request a quote
      </button>
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg" style={{ width: '80%', maxWidth: '600px', maxHeight: '80vh', overflowY: 'auto' }}>
            <h2 className="text-2xl mb-4 text-center">Request a Quote</h2>
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-700">Product:</label>
                  <input
                    type="text"
                    className="border p-2 w-full"
                    value={formEnquiry}
                    onChange={(e) => setFormEnquiry(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-gray-700">Quantity:</label>
                  <input
                    type="number"
                    className="border p-2 w-full"
                    value={formQuantity}
                    onChange={(e) => setFormQuantity(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-700">Full Name:</label>
                  <input
                    type="text"
                    className="border p-2 w-full"
                    value={formFullName}
                    onChange={(e) => setFormFullName(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-gray-700">Delivery Time:</label>
                  <input
                    type="text"
                    className="border p-2 w-full"
                    value={formDeliveryTime}
                    onChange={(e) => setFormDeliveryTime(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-700">Delivery Address:</label>
                  <input
                    type="text"
                    className="border p-2 w-full"
                    value={formDeliveryAddress}
                    onChange={(e) => setFormDeliveryAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-gray-700">Delivery City:</label>
                  <input
                    type="text"
                    className="border p-2 w-full"
                    value={formDeliveryCity}
                    onChange={(e) => setFormDeliveryCity(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex-1">
                <label className="block text-gray-700">Phone Number:</label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={formPhoneNumber}
                  onChange={(e) => setFormPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#4A0000] text-white py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;