import React from "react";

const OrderDetails = () => {
  return (
    <div className="w-full px-5">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Order ID: 3354654654526</h1>
        <div className="flex space-x-2">
          <button className="px-4 py-2 bg-white text-black rounded border border-gray-400">Invoice</button>
          <button className="px-4 py-2 bg-purple-600 text-white rounded">Track order</button>
        </div>
      </div>

      {/* Progress Bar */}
            <div className="mb-8">
        <div className="text-sm mb-2">
            <span className="mr-4">Order date: July 16, 2024</span>
            <span className="font-bold text-green-500">Estimated delivery: July 30, 2024</span>
        </div>

        <div className="flex items-center space-x-2">
            <div className="flex-1 flex flex-col items-center">
            <div className="flex items-center">
                <div className="bg-green-500 w-4 h-4 rounded-full"></div>
                <div className="ml-2 text-green-500">Order Confirmed</div>
            </div>
            <div className="text-xs text-gray-500">Wed, 16 July</div>
            </div>
            <div className="flex-1 flex flex-col items-center">
            <div className="flex items-center">
                <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                <div className="ml-2">Shipped</div>
            </div>
            <div className="text-xs text-gray-500">Thur, 17 July</div>
            </div>
            <div className="flex-1 flex flex-col items-center">
            <div className="flex items-center">
                <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                <div className="ml-2">Out For Delivery</div>
            </div>
            <div className="text-xs text-gray-500">Thur, 17 July</div>
            </div>
            <div className="flex-1 flex flex-col items-center">
            <div className="flex items-center">
                <div className="bg-gray-400 w-4 h-4 rounded-full"></div>
                <div className="ml-2">Delivered</div>
            </div>
            <div className="text-xs text-gray-500">Expected by, Sun 30th</div>
            </div>
        </div>
        </div>


      {/* Product Details */}
      <div className="flex items-center mb-4">
        <img src="https://via.placeholder.com/100" alt="Product" className="w-16 h-16 rounded mr-4"/>
        <div>
          <h2 className="text-lg">Raw Shea Butter</h2>
          <p className="text-gray-500">1 Ton | Ghana</p>
        </div>
        <div className="ml-auto text-right">
          <p className="text-lg font-bold">GH₵2004.23</p>
          <p className="text-gray-500">Qty: 1</p>
        </div>
      </div>

      <hr style={{ margin: '50px 0', borderTop: '1px solid gray', width: '100%' }} />

      {/* Payment and Delivery Information */}
      
      <div className="flex justify-between items-start mb-4">
      <div>
        <p className="font-bold mb-2">Payment</p>
        <div className="flex items-center">
          <img src="https://via.placeholder.com/40" alt="Visa" className="mr-2"/>
          <span>Visa **56</span>
        </div>
      </div>
      <div>
        <p className="font-bold">Address</p>
        <p>Nile House Apt.</p>
        <p>Ogbojo East Legon</p>
        <p>Accra</p>
        <p>+233 535219162</p>
      </div>
    </div>

      <hr style={{ margin: '50px 0', borderTop: '1px solid gray', width: '100%' }} />

      {/* Order Summary */}
      <div className="mt-8">
        <div className="flex justify-between">
          <span>Subtotal</span>
          <span>GH₵1200</span>
        </div>
        <div className="flex justify-between">
          <span>Delivery</span>
          <span>GH₵800</span>
        </div>
        <div className="flex justify-between">
          <span>Tax</span>
          <span>GH₵4.23</span>
        </div>
        <div className="flex justify-between font-bold mt-2">
          <span>Total</span>
          <span>GH₵2004.23</span>
        </div>
      </div>


      <div className="mt-4">
      <div className="flex flex-col text-sm">
        <a href="#" className="text-blue-500 mb-2">Order Issues</a>
        <a href="#" className="text-blue-500 mb-2">Delivery Info</a>
        <a href="#" className="text-blue-500 mb-2">Returns</a>
      </div>
      </div>
      
    </div>
  );
};

export default OrderDetails;
