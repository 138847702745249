import React, { useState, useEffect } from 'react';

const OrderSummary = () => {
  const [orderSummary, setOrderSummary] = useState({
    productName: '',
    productPrice: 0,
    shippingFee: 0,
    totalDue: 0,
  });

  useEffect(() => {
    // Replace with your API endpoint
    fetch()
      .then(response => response.json())
      .then(data => {
        const totalDue = data.productPrice + data.shippingFee;

        setOrderSummary({
          productName: data.productName,
          productPrice: data.productPrice,
          shippingFee: data.shippingFee,
          totalDue,
        });
      })
      .catch(error => console.error('Error fetching order summary:', error));
  }, []);

  return (
    <div className="lg:w-1/3 bg-white p-6 rounded shadow-md mb-6 lg:mb-0">
      <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
      <div className="flex items-center mb-4">
        <img src="path/to/image.jpg" alt="Product" className="w-16 h-16 object-cover rounded mr-4" />
        <div>
          <p>{orderSummary.productName}</p>
          <p className="text-gray-500">BWP{orderSummary.productPrice}</p>
        </div>
      </div>
      <div className="border-t pt-4">
        <div className="flex justify-between mb-2">
          <p>Subtotal</p>
          <p>BWP{orderSummary.productPrice}</p>
        </div>
        <div className="flex justify-between mb-2">
          <p>Shipping Fee</p>
          <p>BWP{orderSummary.shippingFee}</p>
        </div>
        <div className="flex justify-between font-semibold">
          <p>Total due</p>
          <p>BWP{orderSummary.totalDue.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
