import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styles from "../../Styles/Style";
import Collaboration from "../../assets/tracker.jpg";
import Data from "../../assets/businessChart.jpg";
import Transparency from "../../assets/Collaboration.jpg";

function Carousel() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className={`${styles.section} mt-[30px]`}>
      <div className="flex justify-between items-center mb-[20px]">
        <div className="flex items-center">
          <div className="w-5 h-7 bg-[#4A0000] mr-2.5 rounded-sm"></div>
          <h2 className="text-xl font-semibold text-[#4A0000]">Features</h2>
        </div>
      </div>
      <div className={`${styles.heading}`}>
        <h1>Platform Features</h1>
      </div>

      <Slider {...settings}>
        <div className="p-4">
          <div className="bg-[#00838F] p-6 rounded-lg shadow-lg h-[400px]">
            <img src={Transparency} alt="" className="w-full h-48 object-cover rounded-md mb-4"/>
            <h2 className="text-xl text-white font-semibold mb-2">Bridging Trust</h2>
            <p className="text-white">Our solution bridges the uncertainties around payment processing and delivery, enabling buyers to focus on growing their business with the assurance that the supplier will only get paid once they get their delivery.</p>
          </div>
        </div>
        <div className="p-4">
          <div className="bg-[#522915] p-6 rounded-lg shadow-lg h-[400px]">
            <img src={Data} alt="" className="w-full h-48 object-cover rounded-md mb-4"/>
            <h2 className="text-xl text-white font-semibold mb-2">Market Insights</h2>
            <p className="text-white">We connect buyers within the AfCFTA market, offering real-time demand-supply data, market insights, transparent tracking of shipments and secure payment tracking tools.</p>
          </div>
        </div>
        <div className="p-4">
          <div className="bg-[#6C8828] p-6 rounded-lg shadow-lg h-[400px]">
            <img src={Collaboration} alt="" className="w-full h-48 object-cover rounded-md mb-4"/>
            <h2 className="text-xl text-white font-semibold mb-2">Transparent Tracking</h2>
            <p className="text-white">African MSME buyers gain real-time visibility into delivery logistics through our discounted shipping rates and are able to monitor orders from placement to final delivery.</p>
          </div>
        </div>
      </Slider>
      <hr style={{ margin: '50px 0', borderTop: '1px solid gray', width: '100%' }} />
    </div>
  );
}

export default Carousel;
