import React, { useState} from "react";
import Header from "../Components/Layout/Header";
import ProfileSideBar from "../Components/Layout/ProfileSideBar";
import ProductCategory from "../Components/Layout/ProfileContent/ProductCategory";

const MyProductsPage = () => {
  const [active, setActive] = useState(9);

  return (
    <>
      <Header />
      <div className="flex w-full">
        <div className="w-[80px] 800px:w-[335px]">
          <ProfileSideBar active={active} setActive={setActive} />
        </div>

        <div className="w-full mt-10 px-5 ">
          <ProductCategory />
        </div>
      </div>
    
    </>
  );
};

export default MyProductsPage;
