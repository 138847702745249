import React from "react";
import { motion } from "framer-motion";
import ProgressBar from "./ProgressBar";

const Invoice = () => {
  return (
    <div className="w-full px-5">
        <motion.div
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -500 }}
        className="pl-8 pt-1">
    </motion.div>
    <h1 className="text-3xl font-bold mb-4">Invoice: #27346733-022</h1>
      <div className="flex justify-start mb-4">
      <button className="mr-2 px-4 py-2 bg-white text-black text-lg rounded border border-gray-400">Invoice</button>
      <button className="px-4 py-2 bg-purple-600 text-white text-lg rounded">Track payment</button>
      </div>
      <ProgressBar />
      <div className="mb-8">
      <div className="flex justify-between mb-2">
        <div>
          <div className="text-lg">Invoice Date</div>
          <div className="font-bold text-lg">09 August, 2024</div>
        </div>
        <div>
          <div className="text-lg">Due Date</div>
          <div className="font-bold text-lg">10 August, 2024</div>
        </div>
      </div>
        <div className="flex justify-between">
        <div>
          <div className="text-lg">Billed To</div>
          <div className="font-bold text-lg">Ame Givens</div>
          <div>Shea Creations</div>
          <div>9029 Tema, Ghana</div>
          <div>(+233) 521-912-161</div>
        </div>
        <div>
          <div className="text-lg">From</div>
          <div className="font-bold text-lg">Angela Kasungo</div>
          <div>angelak@gmail.com</div>
          <div>Gaborone, Botswana</div>
          <div>(+267) 713-024-08</div>
        </div>
        </div>
      </div>
      <div className="mb-8">
      <table className="w-full text-left">
        <thead>
          <tr>
            <th className="border-b border-gray-700 pb-2 text-lg">Description</th>
            <th className="border-b border-gray-700 pb-2 text-lg">Quantity</th>
            <th className="border-b border-gray-700 pb-2 text-lg">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pt-2 text-lg">Raw Shea Butter</td>
            <td className="pt-2 text-lg">25kg</td>
            <td className="pt-2 text-lg">BWP2170.23</td>
          </tr>
          <tr>
            <td className="pt-2 text-lg">Unrefined shea butter</td>
            <td className="pt-2 text-lg"></td>
            <td className="pt-2 text-lg"></td>
          </tr>
        </tbody>
      </table>

      </div>
      <div className="text-right">
        <div className="mb-2 text-lg">Subtotal: BWP2170.23</div>
        <div className="mb-2 text-lg">Total excluding tax: 0</div>
        <div className="font-bold text-2xl">Total: BWP2170.23</div>
        <div className="font-bold text-2xl">Amount Due: BWP2170.23</div>
      </div>

    </div>
  );
};

export default Invoice;
