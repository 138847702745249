import React, { useState, useEffect } from "react";
import styles from "../../../../Styles/Style";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = ({ setViewProfile }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    // Fetch current profile data from the API
    fetch(apiUrl + "/api/accounts/profile/buyer/update/", {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setEmail(data.email);
        setName(data.full_name);
        setNumber(data.mobile_number);
        setAddress(data.address);
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = {
      full_name: name,
      email: email,
      mobile_number: number,
      address: address,
      current_password: currentPassword,
      new_password: newPassword
    };

    fetch(apiUrl + "/api/accounts/profile/buyer/update/", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify(updatedData)
    })
      .then(response => {
        if (response.ok) {
          toast.success("Your information update successful!");
        } else {
          toast.error("Failed to update profile.");
        }
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        toast.error("Failed to update profile.");
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -500 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -500 }}>
      <div className="flex justify-center w-full">
        <h1 className="text-2xl font-bold mb-4 text-[#4A0000]">Edit Your Profile</h1>
      </div>
      <br />
      <br />
      <div className="w-full px-5">
        <form onSubmit={handleSubmit} aria-={true}>
          <div className="flex w-full flex-wrap pb-3">
            {/* name input */}
            <div className="w-full 800px:w-[50%] pb-4">
              <label htmlFor="name" className="block pb-2">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* email input */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="email" className="block pb-2">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* phone number input */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="phoneNumber" className="block pb-2">
                Phone Number
              </label>
              <input
                type="number"
                name="phoneNumber"
                id="number"
                required
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* address input */}
            <div className="w-full 800px:w-[50%] 800px:pb-4 pb-6">
              <label htmlFor="address" className="block pb-2">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* current password input */}
            <div className="w-full 800px:w-[100%] 800px:pb-4 pb-6">
              <label htmlFor="currentPassword" className="block pb-2">
                Current Password
              </label>
              <input
                type="password"
                name="currentPassword"
                id="currentPassword"
                required
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* new password input */}
            <div className="w-full 800px:w-[100%] 800px:pb-4 pb-6">
              <label htmlFor="newPassword" className="block pb-2">
                New Password
              </label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={`${styles.input} !w-[95%] focus:border-[#3957db]`}
              />
            </div>

            {/* button */}
            <div className="rounded-[5px] w-[30%] transition h-11 bg-[#4A0000] hover:bg-[#DB4444] hover:text-white flex items-center justify-center">
              <button type="submit" className="text-white w-full h-full">
                Update
              </button>
            </div>
          </div>
          {/* toast message */}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
        </form>
      </div>
    </motion.div>
  );
};

export default Profile;